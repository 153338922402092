/*
 * @Descripttion: 这是描述
 * @Date: 2022-03-19 09:23:01
 * @LastEditTime: 2022-03-22 16:55:57
 */
import agentPurchaseLogsPage from "@/views/agentPurchaseLogs"

export default [
    {
      name: 'agentPurchaseLogs',
      path: '/agentPurchaseLogs',
      meta: {
        id:'1',
        icon:'Document',
        title: 'agentPurchaseLogs',
        name:"agent_purchase_logs",
        cache: true,
		permission:"agentPurchaseLogs"
      },
      component: agentPurchaseLogsPage
    },
  ]
  