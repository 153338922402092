<template>
  <div class="contain">
    <div class="center u-flex u-flex-col u-row-center" v-if="init_data">
      <div class="box">
        <div class="right-container">
          <div class="loginForm">
            <div class="title">{{$t("login")}}</div>
            <el-form
                    :model="form"
                    status-icon
                    :rules="rules"
                    ref="loginForm"
                    label-width="100px"
                    class="login-container"
                    @keyup="confirmForm"
            >
              <el-form-item prop="username">
                <el-input
						:prefix-icon="Message"
                        class="input"
                        v-model="form.username"
                        name="username"
                        ref="username"
                        tabindex="1"
                        :placeholder='$t("please_input_account")'
                        clearable
                        auto-complete="off"
                >
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
						:prefix-icon="Lock"
                        type="password"
                        v-model="form.password"
                        class="input"
                        name="password"
                        tabindex="2"
                        :placeholder='$t("please_input_pwd")'
                        clearable
                        @blur="capsTooltip = false"
                        @keyup.enter.native="submitForm"
                        auto-complete="off"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="bottom">
            <el-button
                    class="loginBtn"
                    :loading="loading"
                    @click.native.prevent="submitForm"
                    type="primary"
            >{{$t("login")}}</el-button>
			<div class="tip" v-if="init_data.site_out_link">
						  {{$t("no_account")}}<a :href="init_data.site_out_link" target="_blank">{{$t("register_in_app")}}</a>
			</div>
          </div>
        </div>
      </div>
      <div class="copyright">
        {{ init_data.site_copyright }}
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import config from '@/config'
import i18n from '@/lang'
import { Message,Lock } from '@element-plus/icons-vue'

export default {
  name: 'loginPage',
  setup() {
    const store = useStore()
    const router = useRouter()
	let loading = ref(false)
	const { t } = i18n.global

    const rules = {
      username: [
        {
          required: true,
          trigger: 'blur',
          message: t("need_account"),
        },
      ],
      password: [
        {
          required: true,
          trigger: 'blur',
          message: t("need_pwd"),
        },
      ],
    }

    const form = ref({
      username: null,
      password: null,
    })

    const loginForm = ref(null)

    const submitForm = () => {
		loading.value = true
      loginForm.value.validate((valid) => {
        if (valid) {
          store
            .dispatch('loginHandle', form.value)
            .then(() => {
              router.push({
                name: config.homeRouteName,
              })
            })
            .catch((error) => {
              // 底层已经提示错误了，这里忽略处理
			  loading.value = false
            })
        }
		else{
			loading.value = false
		}
      })
    }

    const resetForm = () => {
      loginForm.value.resetFields()
    }

    const confirmForm = (e) => {
      if (e.code !== 'Enter') {
        return false
      }
      submitForm()
    }

    return {
      rules,
      form,
      submitForm,
      resetForm,
      loginForm,
      confirmForm,
      init_data: {
        title: config.fullName,
        site_name: store.state.env.APP_FULL_NAME || '',
        site_logo: require('@/assets/logo_white.png'),
        site_out_link: store.state.env.APP_OUT_LINK || "",
        site_copyright: (store.state.env.APP_ALL_RIGHTS || '') + ' ' + (store.state.env.APP_ICP || ''),
        service_url: '',
        help_base_url: '',
        alivcode_appkey: ''
      },
	  loading,
	  Message,
	  Lock
    }
  },
}
</script>

<style lang="scss" scoped>
  .autoLogin {
    margin-bottom: 213px;
  }
</style>

<style lang="scss">
  $plane_padding: 40px;
  .loginForm .el-input__wrapper {
    box-shadow: none!important;
    padding: 0!important;
  }
  .contain {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: #f9fbff;
    display: flex;
    justify-content: center;
    position: relative;
    .login-tabs-choose {
      .el-tabs__header {
        margin-bottom: 40px;
      }
      .el-tabs__active-bar {
        width: calc(50% - #{$plane_padding});
      }
      .el-tabs__nav-scroll {
        display: flex;
        .el-tabs__nav {
          margin: 0 auto;
        }
        .el-tabs__item {
          font-size: 20px;
          padding: 0 $plane_padding;
        }
      }
      .el-tabs__nav-wrap::after {
        height: 0;
      }
    }
    .center {
      position: relative;
      min-height: 662px;
      .box {
        width: 500px;
        /* height: 542px; */
        height: 380px;
        background: #ffffff;
        box-shadow: 0 4px 36px -4px rgba(0, 110, 255, 0.28);
        border-radius: 10px;
        display: flex;
        .left-container {
          width: 376px;
          height: 100%;
          background: linear-gradient(
                          180deg,
                          rgba(46, 83, 219, 0.88),
                          rgba(24, 139, 255, 0.8)
          );
          border-radius: 10px 0 0 10px;
          .logo {
            /* margin-top: 158px; */
            margin-top: 128px;
            /*width: 315px;*/
            height: 36px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left center;
            padding: 0 0 0 45px;
            font-size: 28px;
            font-weight: 500;
            color: #fff;
          }
          .brline {
            width: 72px;
            height: 1px;
            background: #ffffff;
            margin: 32px 0 40px;
            opacity: 0.5;
          }
          .desrc {
            p {
              font-size: 18px;
              color: #fff;
              line-height: 28px;
              letter-spacing: 2px;
              text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
            }
          }
        }
        .right-container {
          position: relative;
          flex: 1;
          height: 100%;
          .loginForm {
           /* width: 100%; */
            padding: 40px 50px 0;
            transition: all 0.5s;
            .title {
              height: 50px;
              font-size: 36px;
              font-weight: 800;
              color: #000;
              line-height: 50px;
              text-align: center;
              margin-bottom: 20px;
            }
            .el-form-item {
              margin-bottom: 10px;
              .el-form-item__content {
                margin-left: 0!important;
                margin-top: 15px;
                line-height: 40px;
                position: relative;
                font-size: 14px;
                .el-form-item__error {
                  left: auto;
                  right: 0;
                  top: 17%;
                  font-size: 14px;
                  color: #ff613d;
                  line-height: 20px;
                  padding: 0 35px 0 0;
                }
                .el-input {
                  border-bottom: 1px solid #f5f5f5;
                  .el-input__inner {
                    border: none;
                    box-shadow: none;
                    padding: 0;
                    font-size: 14px !important;
                    color: #222 !important;
                    -webkit-box-shadow: 0 0 0px 1000px white inset;
                  }
                }
              }
            }
            .autoLogin {
              height: 16px;
              .el-checkbox__label {
                padding-left: 6px;
                font-weight: 400;
                font-size: 12px;
              }
            }
          }
          .bottom {
            text-align: center;
            position: absolute;
            width: 100%;
            bottom: 24px;
          }
          .loginBtn {
            font-size: 14px;
            font-weight: 400;
            border-radius: 8px;
            background: #7559ff;
            width: 388px;
            height: 50px;
            box-shadow: 0 2px 6px 0 rgba(117, 89, 255, 0.32);
			border-radius: 10px;
			border:#7559ff ;
            /* width: 100%; */
          }
          .register {
            margin-top: 16px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            span {
              height: 17px;
              font-size: 12px;
              color: #888;
              line-height: 17px;
            }
            .to-reg {
              color: #006eff;
            }
          }
        }
      }
      .copyright {
        font-size: 12px;
        color: #b5bbc4;
        position: absolute;
        bottom: 30px;
      }
    }
  }
  .tip{
	  text-align: center;
	  font-size: 14px;
	  margin-top: 20px;
	  color:#999999;
	  a{
		  color:#0066FF ;
		  text-decoration: none;
	  }
  }
  .el-input__prefix{
	  font-size: 24px;
  }
</style>