/*
 * @Descripttion: 
 * @Date: 2022-04-22 16:11:04
 * @LastEditTime: 2022-04-26 16:45:01
 */
import http from '@/utils/http'

//用户详情 id
export const getUser = (params) => {
    return http.get('user/info', { params })
};

export const getCaptcha = (params) => {
    return http.get('captcha/image/generate', { params })
};

export const sendCaptcha = (params) => {
    return http.post('captcha/text/send', params)
};

export const saveWithdraw = (params) => {
    return http.post('withdraw/save', params)
};

export const getBank = (params) => {
    return http.get('userbank/get', params)
};

export const saveBank = (params) => {
    return http.post('userbank/save', params)
};

export const getAgent = (params) => {
    return http.post('agent-apply/get', params)
};

export const saveAgent = (params) => {
    return http.post('agent-apply/save', params)
};

export const payAgent = (params) => {
    return http.post('agent-apply/pay', params)
};
export const getPayResult = (params) => {
    return http.get('/payment/getPaymentStatus', {params})
};

export const cancelAgent = (params) => {
    return http.post('agent-apply/cancel', params)
};

export const areaJs = (params) => {
    return http.post('area_js', params)
};
export const addUser = (params) => {
	return http.post('user/adduser', params)
}