<!--
 * @Descripttion: 这是描述
 * @Date: 2022-03-19 10:29:56
 * @LastEditTime: 2022-05-07 11:22:00
-->
<template>
	<el-aside :width="isCollapse ? '75px' : '200px'">
		<div style="
        display: flex;
        flex-direction: column;
        height: 100%;
        background: linear-gradient(178.45deg, rgb(20, 20, 45) 0%, rgb(32, 41, 65) 100%);
        border-right: 1px solid rgb(49, 58, 82);
      ">
			<div class="logo">
				<div v-if="!isCollapse" class="normal">
					{{ fullName }}
				</div>
				<div v-else class="mini">
					{{ abbrName }}
				</div>
			</div>
			<div style="flex: auto; overflow-y: auto">
				<el-scrollbar height="100%">
					<el-menu class="el-menu-vertical" :collapse="isCollapse" :router="true" :default-active="curActive"
						v-model="curActive" text-color="rgba(255, 255, 255, 0.5)" :collapse-transition="false"
						:unique-opened="true" active-text-color="#ffffff">
						<nav-item v-for="item in menuItems" :item="item" :key="item.path"></nav-item>
					</el-menu>
				</el-scrollbar>
			</div>
		</div>
	</el-aside>
</template>

<script>
	import NavItem from './NavItem.vue'
	import config from '@/config'
	import routes from '@/router/routes'
	import {
		ref,
		computed
	} from 'vue'
	import {
		useRoute,
		onBeforeRouteUpdate
	} from 'vue-router'
	import {
		useStore
	} from 'vuex'
	export default {
		name: 'NavBar',
		components: {
			NavItem,
		},
		props: {
			isCollapse: Boolean,
		},
		setup(props, context) {
			const route = useRoute()
			const store = useStore()

			const curActive = ref(route.matched[route.matched.length - 1].path)
			let menuItems = ref([])
			store.dispatch('loadPermissions').then(res => {
				routes[0].children.forEach(item => {
					let children = []
					if (item.hasOwnProperty('children')) {
						item.children.forEach(item => {
							console.log(item.mata)
							let isShow = item.meta.hasOwnProperty('show') ? item.meta.show : true;

							if (item.meta.hasOwnProperty('supportModuleName') && store.getters
								.hasSupportModule(item.meta.supportModuleName)) {
								isShow = true
							}

							if (isShow) {
								children.push(item)
							}
						})
					}
					if (item.meta.permission == 'home' || store.getters.hasPermission(item.meta.permission)) {
						let menu = Object.assign({}, item)
						if(!menu.meta.hasOwnProperty('show') || (menu.meta.hasOwnProperty('show') && menu.meta.show == true))
							menuItems.value.push(menu)
					}
				})
			})

			onBeforeRouteUpdate((to) => {
				let curPath = to.path;
				if (curActive.value != curPath) {
					curActive.value = curPath;
				}
			})

			return {
				menuItems,
				curActive,
				fullName: config.fullName,
				abbrName: config.abbrName,
			}
		},
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.el-menu-vertical {
		height: 100%;
		border-right: 0;
	}

	.logo {
		height: 52px;
		display: flex;
		line-height: 52px;
		color: #42b983;
		width: 100%;

		.normal {
			
		}

		.mini {
			width: 100%;
			text-align: center;
		}
	}
</style>