import http from '@/utils/http'

export const getBonusLogsList = (params) => {
    return http.get('/bonus_logs/list', { params })
};
export const getRefferalUsersList = (params) => {
    return http.get('/reffera_users/list', { params })
};
export const getRegionalUsersList = (params) => {
    return http.get('/regional_users/list', { params })
};
export const getWithdrawList = (params) => {
    return http.get('/withdraw/list', { params })
};
export const getPurchaseLogs = (params) => {
    return http.get('/agent-purchase-logs/list', { params })
};