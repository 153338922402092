/*
 * @Descripttion: 这是描述
 * @Date: 2022-04-13 09:18:11
 * @LastEditTime: 2022-04-29 16:49:34
 */
export default {
    locale: 'zh-cn',
	
	guard: 'bonus',
	
	locale:'locale:',

    switchLanguage: false,

    showAuthorGitHubUrl: false,

    homeRouteName: 'main',

    dashboardName: 'dashboard',

    loginRouteName: 'login',

    dashboardFullPath: '/dashboard',

    fullName: window._env && window._env.APP_FULL_NAME ? window._env && window._env.APP_FULL_NAME : '代理分销系统',

    abbrName: window._env && window._env.APP_ABBR_NAME ? window._env && window._env.APP_ABBR_NAME : '分销系统',

    paymentTypes: {
        alinativepay: '支付宝',
        wxnativepay: '微信',
    },

    orderStatus: {
        0: '待支付',
        1: '已支付',
        2: '已完成',
        3: '已取消',
    }
}