export default {
    "base_info":"基本信息",
	"email" : "邮箱地址",
	"mobile" :"手机号码",
	"short_email" : "邮箱",
	"short_mobile" :"手机",
	"password" : "登录密码",
	"not_set" : "未设置",
	"paypassword" : "支付密码",
	"balance" : "账户余额",
	"withdraw" : "提现",
	"invitation_url" : "推广链接",
	"copy_success" : "复制成功",
	"withdraw_log" : "提现记录",
	"refresh" : "刷新",
	"filter" : "筛选",
	"withdraw_amount" : "提现金额",
	"withdraw_type" : "提现方式",
	"withdraw_account":"提现账号",
	"withdraw_status" : "提现状态",
	"status":"状态",
	"memo" : "备注",
	"time" : "时间",
	"daterange" : "起止时间",
	"to":"到",
	"start_time" : "开始时间",
	"end_time" : "结束时间",
	"reset" : "重置",
	"please_input_vercode" : "请输入验证码",
	"send_input_vercode" : "发送验证码",
	"new_pwd" : "新密码",
	"re_pwd" : "确认新密码",
	"pwd_tip" : "6-35个字符，包含大小字母，小写字母和数字",
	"pre_step" : "上一步",
	"next_step" : "下一步",
	"submit" : "确定",
	"please_input_piccode" : "请输入图片验证码",
	"send":"发送",
	"can_withdraw_amount" : "可提现金额",
	"cancel" : "取消",
	"change_pwd" : "修改密码",
	"change_pwd_tip" : "修改成功后会自动退出当前登录！",
	"old_pwd" : "旧密码",
	"login" : "登录",
	"please_input_account" : "请输入登陆账号",
	"please_input_pwd" : "请输入密码",
	"need_account" : "用户名是必填",
	"need_pwd" : "密码是必填的",
	"bonus_log" : "分成记录",
	"consume_username":"消费用户",
	"consume_amount":"消费金额",
	"consume_type":"消费类型",
	"bonus_type":"分成类型",
	"bonus_rate":"分成比例",
	"bonus_amount":"分成金额",
	"user_info" : "用户信息",
	"referral_user":"推广用户",
	"user":"用户",
	"grade" :"身份级别",
	"invitation_level":"层级关系",
	"order_count":"消费笔数",
	"order_amount":"消费总额",
	"area_user" : "区内用户",
	"region":"归属地区",
	"user_totle_line" : "推广用户总数",
	"grade_0" : "推广普通用户数",
	"grade_1" : "推广分销数",
	"grade_2" : "推广区级代理数",
	"grade_3" : "推广市级代理数",
	"grade_4" : "推广省级代理数",
	"total_bonus_count_line" : "分成总笔数",
	"count_order_type_10" : "产品购买数",
	"count_order_type_11" : "产品续费数",
	"count_order_type_12" : "增值服务数",
	"count_order_type_20" : "代理加盟数",
	"count_order_type_21" : "代理续费数",
	"count_bonus_type_10" : "直推分成数",
	"count_bonus_type_11" : "间推分成数",
	"count_bonus_type_20" : "省代分成数",
	"count_bonus_type_21" : "市代分成数",
	"count_bonus_type_22" : "区代分成数",
	"total_bonus_amount_line" : "分成总金额",
	"amount_order_type_10" : "产品购买额",
	"amount_order_type_11" : "产品续费额",
	"amount_order_type_12" : "增值服务额",
	"amount_order_type_20" : "代理加盟额",
	"amount_order_type_21" : "代理续费额",
	"amount_bonus_type_10" : "直推分成额",
	"amount_bonus_type_11" : "间推分成额",
	"amount_bonus_type_20" : "省代分成额",
	"amount_bonus_type_21" : "市代分成额",
	"amount_bonus_type_22" : "区代分成额",
	"num_of_user":"推广用户数",
	"by_order_type":"按消费类型分",
	"by_bonus_type":"按分成类型分",
	"set_pwd":"设置登陆密码",
	"set_paypwd":"设置支付密码",
	"near_7_days" : "最近7天",
	"near_15_days" : "最近15天",
	"near_30_days" : "最近30天",
	"near_1_year" : "最近1年",
	"bank_card":"银行卡",
	"withdraw_info":"提现账号信息",
	"name":"姓名",
	"card_number":"卡号",
	"bank_name" : "开户行",
	"save":"保存",
	"pleace_input_name":"请输入姓名",
	"pleace_input_cardno":"请输入卡号",
	"pleace_input_bankname":"请输入开户行",
	"agent_apply" : "申请代理",
	"apply_has_submit_waiting":"您的代理申请已提交，请等待平台审核...",
	"apply_has_pass_pay":"您的代理申请已审核通过，请及时支付代理费用！",
	"apply_has_paid_waiting":"您的代理申请已审核通过，并已支付代理费用，请等待平台确认...",
	"apply_has_paid":"您已正式签约成为本平台代理，您将享受本平台的代理提成！",
	"agent_area":"代理区域",
	"company_name":"公司名称",
	"company_size":"公司规模",
	"work_address":"办公地址",
	"work_address":"请输入详细地址",
	"company_size":"公司规模",
	"contact_person" : "联系人",
	"please_input_contact_person":"请输入联系人姓名",
	"contact_type":"联系方式",
	"service_phone":"服务电话",
	"pleace_input_service_phone":"请输入电话号码",
	"apply_info":"申请说明",
	"submit_apply":"提交申请",
	"agent_fee":"代理费用",
	"apply_time":"申请时间",
	"paid_time":"支付时间",
	"service_address":"服务地址",
	"next_is_area_agent_info":"以下是您的省级代理商信息",
	"pay_now":"立即支付",
	"cancel_agent":"撤销申请",
	"apply_pass_time":"签约时间",
	"no_account":"还没有账号？",
	"register_in_app":"立即到应用系统注册",
	"wechat" : "微信",
	"account":"账号",
	"pleace_input_account":"请输入账号",
	"alipay":"支付宝",
	"bonus_grade":"代理等级",
	"pay_balance":"货款余额",
	"agent_purchase_logs":"货款记录",
	"amount":"金额",
	"direction":"出入账方向",
	"description":"出入账描述",
	"in":"入账",
	"out":"出账",
	"order_no":"关联订单号",
	"add_user":"添加用户",
	"please_input_mobile" : "请填写手机号",
	"please_input_emial":"请填写邮箱",
	"please_input_pwd" : "请填写密码",
	"please_confirm_pwd":"请确认密码",
	"passwords_do_not_match":"两次密码不一致",
	"home":"首页",
	"login_out":"退出登录",
	"pleace_input_account":"请输入账号",
	"alipay":"支付宝",
	"phone":"电话",
	"please_input_agent_area":"请选择代理类型和区域",
	"please_input_company_name":"请填写公司名称",
	"please_select_company_size":'请选择公司规模',
	"please_input_contact_name":'请填写联系人姓名',
	"please_select_contact_information":"请选择联系方式",
	"please_input_the_application_description":"请输入申请说明",
	"action_success":"操作成功",
	"order_failed_please_initiate_payment_agent":"订单支付失败，请重新发起支付",
	"payment_order_does_not_exist":"支付订单不存在",
	"please_select_the_verification_method":"请选择验证方式",
	"please_enter_the_verification_code":"请输入验证码",
	"please_enter_a_new_password":"请输入新密码",
	"please_confirm_the_new_password":"请确认新密码",
	"the_two_passwords_are_different":"两次密码不一样",
	"buy_desc":"费用：$1（$2折购买$3套产品，产品原价：￥$4/套）"
}