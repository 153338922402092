<template>
	<div>
		<el-card :loading="loading">
			<div class="u-flex u-100 u-m-b-20">
				<div>
					<span class="page-title">{{$t('agent_apply')}}</span>
				</div>
			</div>
			<p v-if="form.status === 0">
				{{$t('apply_has_submit_waiting')}}
			</p>
			<p v-if="form.status === 1">
				{{$t("apply_has_pass_pay")}}
			</p>
			<p v-if="form.status === 2">
				{{$t("apply_has_paid_waiting")}}
			</p>
			<p v-if="form.status === 3">
				{{$t("apply_has_paid")}}
			</p>
			<div v-if="form.status === 10 || form.status === 11">
				<el-form :model="form" :rules="rules" ref="formRef">
					<el-form-item :label='$t("agent_area")' 
						:label-width="formLabelWidth" prop="grade">
						<el-select v-model="form.grade" :placeholder="$t('agent_area')" clearable @change="changeGrade">
							<el-option v-for="(item,index) in agent_type" 
								:label="item.name" 
								:value="item.name" 
								:index="index"
							>
							</el-option>
						</el-select>
						<el-cascader
							:options="area_data"
							v-model="form.selectedArea"
							@change="handleArea">
					   </el-cascader>
					</el-form-item>
					<el-form-item :label-width="formLabelWidth">
					   <span>{{grade_str}}</span>
					</el-form-item>
					<el-form-item prop="company_name" :label='$t("company_name")' :label-width="formLabelWidth">
						<span><el-input v-model="form.company_name" placeholder="请输入公司名称"></el-input></span>
					</el-form-item>
					<el-form-item prop="company_size" :label='$t("company_size")' :label-width="formLabelWidth">
						<el-radio-group v-model="form.company_size">
							<el-radio v-for="(item,index) in company_size" :label="item">{{item}}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item prop="work_address" :label='$t("work_address")' :label-width="formLabelWidth">
						<div style="line-height: 40px;">
							<div>
								<el-cascader
									:options="area_arr.DISTRICT_AGENT"
									v-model="form.selectedAddress"
									@change="handleAddress">
								</el-cascader>	
							</div>
							<span>
								<el-input v-model="form.work_address" 
									:placeholder="$t('work_address')"></el-input>
							</span>
						</div>	
					</el-form-item>
					<el-form-item prop="contact_person" :label='$t("contact_person")'
						:label-width="formLabelWidth">
						<span>
							<el-input v-model="form.contact_person"
							 :placeholder="$t('please_input_contact_person')"></el-input>
						</span>
					</el-form-item>
					<el-form-item prop="contact_type" 
						:label='$t("contact_type")' :label-width="formLabelWidth">
						<div style="line-height: 40px;">
							<el-radio-group v-model="form.contact_type">
								<el-radio v-for="(item,index) in contact_type" :label="index">{{item}}</el-radio>
							</el-radio-group>
							<span>
								<el-input v-model="form.contact_number" placeholder="请输入电话号码"></el-input>
							</span>
						</div>
					</el-form-item>
					<el-form-item prop="service_phone" :label='$t("service_phone")' :label-width="formLabelWidth">
						<span>
							<el-input v-model="form.service_phone" 
								:placeholder="$t('pleace_input_service_phone')"></el-input>
						</span>
					</el-form-item>
					<el-form-item prop="apply_info" :label='$t("apply_info")' :label-width="formLabelWidth">
						<span>
							<el-input v-model="form.apply_info" type="textarea"></el-input>
						</span>
					</el-form-item>
					<div class="u-p-l-150 u-m-t-15">
						<el-button type="primary" class="u-m-l-10"
							@click="handleEditSave">{{$t("submit_apply")}}</el-button>
					</div>
				</el-form>
			</div>
			<div v-else>
				<el-form >
					<el-form-item :label='$t("agent_area")' :label-width="formLabelWidth" prop="grade">
						{{form.grade}}-{{form.province}}{{form.city}}{{form.district}}
					</el-form-item>
					<el-form-item :label='$t("company_name")' :label-width="formLabelWidth">
						{{form.company_name}}
					</el-form-item>
					<el-form-item :label='$t("company_size")' :label-width="formLabelWidth">
						{{form.company_size}}
					</el-form-item>
					<el-form-item  :label='$t("work_address")' :label-width="formLabelWidth">
						{{form.work_province}}{{form.work_city}}{{form.work_district}}{{form.work_address}}
					</el-form-item>
					<el-form-item :label='$t("contact_person")' :label-width="formLabelWidth">
						{{form.contact_person}}
					</el-form-item>
					<el-form-item  :label='$t("contact_type")' :label-width="formLabelWidth">
						{{form.contact_type}}:{{form.contact_number}}
					</el-form-item>
					<el-form-item :label='$t("service_phone")' :label-width="formLabelWidth">
						{{form.service_phone}}
					</el-form-item>
					<el-form-item  :label='$t("apply_info")' :label-width="formLabelWidth">
						{{form.apply_info}}
					</el-form-item>
					<el-form-item  :label='$t("agent_fee")' :label-width="formLabelWidth">
						{{grade_str}}
					</el-form-item>
					<el-form-item :label='$t("apply_time")' :label-width="formLabelWidth">
						{{form.created_at}}
					</el-form-item>
					<el-form-item v-if="form.status == 2 || form.status == 3" :label='$t("paid_time")' :label-width="formLabelWidth">
						{{form.pay_time}}
					</el-form-item>
					<el-form-item v-if="form.status == 3" :label='$t("apply_pass_time")' :label-width="formLabelWidth">
						{{form.pass_time}}
					</el-form-item>
					<el-form-item v-if="form.status == 3" :label='$t("next_is_area_agent_info")' :label-width="formLabelWidth">

					</el-form-item>
					<el-form-item v-if="form.status == 3" :label='$t("company_name")' :label-width="formLabelWidth">
						{{form.company_name}}
					</el-form-item>
					<el-form-item v-if="form.status == 3" :label='$t("service_name")' :label-width="formLabelWidth">
						{{form.service_name}}
					</el-form-item>
					<el-form-item v-if="form.status == 3" :label='$t("service_address")' :label-width="formLabelWidth">
						{{form.work_province}}{{form.work_city}}{{form.work_district}}{{form.work_address}}
					</el-form-item>
					<div class="u-p-l-150 u-m-t-15">
						<el-button type="primary" v-if="form.status == 0" class="u-m-l-10"
							@click="handleCancel">{{$t("cancel_agent")}}</el-button>
						<el-button type="primary" v-if="form.status == 1" class="u-m-l-10"
							@click="handlePay">{{$t("pay_now")}}</el-button>	
					</div>
				</el-form>
			</div>
		</el-card>
		<el-dialog width="350px" v-model="showQrcode">
			<div>
				<el-image :src="qrcode" style="width: 300px;">
					
				</el-image>
			</div>
			<div style="text-align: center; margin-top: 30px;">
				<el-button type="primary"  @click="closeQrcode">{{$t("submit")}}</el-button>
			</div>	
		</el-dialog>
	</div>	
</template>

<script>
	import {
		reactive,
		ref
	} from '@vue/reactivity';
	import useClipboard from 'vue-clipboard3'
	import notice from '@/utils/notice'
	import {
		useStore
	} from 'vuex'
import { saveAgent,getAgent,cancelAgent,payAgent,areaJs,getPayResult } from '@/api/users';
import { useRouter } from "vue-router";
import http from '@/utils/http'
import i18n from '@/lang'
	export default {
		name: 'config',
		components: {
		},
		setup() {
			const store = useStore();
			const router = useRouter();
			const { t } = i18n.global
			const area_data = ref([])
			const area_arr = ref({
				"PROVINCE_AGENT":[],
				"CITY_AGENT":[],
				"DISTRICT_AGENT":[]
			})
			const grade_str = ref("")
			const showQrcode = ref(false)
			const qrcode = ref("")
			const timer = ref({})
			let form = ref({
			  grade: '',
			  selectedArea:[],
			  selectedAddress:[],
			  country: '',
			  province: '',
			  city: '',
			  district: '',
			  company_name:'',
			  company_size:'0~9人',
			  service_phone:'',
			  work_country:'',
			  work_province:'',
			  work_city:'',
			  work_district:'',
			  work_address:'',
			  contact_person:'',
			  contact_type:'phone',
			  contact_number:'',
			  apply_info:'',
			  status:10
			})
			const formRef = ref(null)
			const company_size = ref([
				'0~9人',
				'10~49人',
				'50~99人',
				'100人以上'
			])
			const contact_type = ref({
				'phone':t('phone'),
				'email':t('short_email'),
				'qq':'QQ',
				'wechat':t("wechat")
			})
			const agent_type = ref([
				
			])
			const rules = {
				'grade':[
					{required:true,message:t("please_input_agent_area"),trigger: 'blur'}
				],
				'company_name':[
					{required:true,message:t("please_input_company_name"),trigger: 'blur'}
				],
				'company_size':[
					{required:true,message:t("please_select_company_size"),trigger: 'blur'}
				],
				'contact_person':[
					{required:true,message:t("please_input_contact_name"),trigger: 'blur'}
				],
				'contact_type':[
					{required:true,message:t("please_input_contact_name"),trigger: 'blur'}
				],
				'contact_number':[
					{required:true,message:t("please_select_contact_information"),trigger: 'blur'}
				],
				'apply_info':[
					{required:true,message:t("please_input_the_application_description"),trigger: 'blur'}
				],
			}
			let formLabelWidth = '150px';
			let loading = ref(false)
			
			const requestData = (params) => {
			  loading.value = true
			  agent_type.value = []
			  getAgent().then((response) => {
				  console.log(response.data.setting)
				  response.data.setting.forEach((item,index)=>{
					  if(response.data.setting[index].enabled == true){
						agent_type.value.push(item)
					  }
				  })
				  if(response.data.agent_apply)
				  {
					form.value = response.data.agent_apply
					if(form.value.grade == 2){
						form.value.grade = "DISTRICT_AGENT"
					}
					else if(form.value.grade == 3){
						form.value.grade = "CITY_AGENT"
					}
					else if(form.value.grade == 4){
						form.value.grade = "PROVINCE_AGENT"
					}
					changeGrade(form.value.grade)
				  }
				  form.value.selectedArea = [form.value.province,form.value.city.form.value.district]
				  form.value.selectedAddress = [form.value.work_province,form.value.work_city.form.value.work_district]
				  loading.value = false
			  }).catch(()=>{
				  loading.value = false
			  })
			}
			const getArea = () => {
				areaJs().then((response)=>{
					area_arr.value['DISTRICT_AGENT'] = JSON.parse(JSON.stringify(response.data))
					response.data.forEach((item,index)=>{
						if(item.children.length > 0)
						{
							item.children.forEach((itm,idx)=>{
								delete itm.children
								area_arr.value['CITY_AGENT'].push(JSON.parse(JSON.stringify(itm)))
							})
						}
						delete item.children
						area_arr.value['PROVINCE_AGENT'].push(item) 
					})
				}).catch(()=>{
					
				})
			}
			const handleArea = ()=>{
				form.value.province = form.value.selectedArea[0];
				if(form.value.selectedArea[1])
					form.value.city = form.value.selectedArea[1]
				if(form.value.selectedArea[2])	
					form.value.district = form.value.selectedArea[2]
			}
			const handleAddress = ()=>{
				form.value.work_province = form.value.selectedAddress[0];
				if(form.value.selectedAddress[1])
					form.value.work_city = form.value.selectedAddress[1]
				if(form.value.selectedAddress[2])
					form.value.work_district = form.value.selectedAddress[2]
			}
			const changeGrade = (val)=>{
				area_data.value = []
				if(val)
				{
					let grade_item = {}
					agent_type.value.map((item)=>{
						if(item.name == val)
						{
							grade_item = item
						}
					})
					let s_money = (parseFloat(grade_item.price) * parseFloat(grade_item.discount) * parseFloat(grade_item.count)).toFixed(2)
					let s_discount = grade_item.discount * 10 
					let s_count = grade_item.count
					let s_price = grade_item.price
					
					grade_str.value = t("buy_desc").replace('$1',s_money)
						.replace('$2',s_discount)
						.replace('$3',s_count)
						.replace('$4',s_price)
					
					area_data.value = area_arr.value[val]
				}
				form.value.province = "";
				form.value.city = ""
				form.value.district = ""
				form.value.selectedArea = []
			}
			const handleEditSave = ()=>{
				formRef.value.validate((valid) => {
					if (valid) {
						if(form.grade == 'DISTRICT_AGENT'){
							form.value.grade = 2
						}
						else if(form.grade == 'CITY_AGENT'){
							form.value.grade = 3
						}
						else if(form.grade == 'PROVINCE_AGENT'){
							form.value.grade = 4
						}
						saveAgent(form.value).then((response)=>{
							notice.success(t("action_success"))
							requestData()
						})
					}
					else{
						return false;
					}
				})
			}
			const handleCancel = ()=>{
				cancelAgent().then(()=>{
					notice.success(t("action_success"))
					requestData()
				})
			}
			const handlePay = ()=>{
				payAgent().then((response)=>{
					qrcode.value = response.data.qrcode;
					showQrcode.value = true;
					//requestData()
					timer.value = setInterval(function(){
						let data = {}
						data.payment_no = response.data.payment_no
						getPayResult(data).then((res)=>{
							if(res.data){
								if(res.data.status == 1){
									clearInterval(timer.value)
									showQrcode.value = false;
									requestData()
								}
								if(res.data.status == 2){
									clearInterval(timer.value)
									showQrcode.value = false;
									notice.failed(t("order_failed_please_initiate_payment_agent"))
								}
							}
							else{
								clearInterval(timer.value)
								showQrcode.value = false;
								notice.failed(t("payment_order_does_not_exist"))
							}
						})
					},3000)
				})
			}
			const closeQrcode = ()=>{
				showQrcode.value = false;
				clearInterval(timer);
			}
			getArea()
			requestData()
			return {
				form,
				formLabelWidth,
				requestData,
				loading,
				company_size,
				contact_type,
				rules,
				area_data,
				handleArea,
				handleAddress,
				agent_type,
				grade_str,
				changeGrade,
				handleEditSave,
				area_arr,
				formRef,
				handleCancel,
				handlePay,
				closeQrcode,
				qrcode,
				showQrcode,
				timer
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content-box {
		align-items: flex-start;
	}

	.img-box {
		position: relative;
	}

	.del-icon {
		color: red;
		background: #fff;
		font-size: 26px;
		border-radius: 13px;
		position: absolute;
		top: -5px;
		right: -5px;
		cursor: pointer;
	}

	.el-link {
		font-size: 16px;
		vertical-align: top;
	}

	.tips-span {
		padding: 0 0 20px 45px;
		color: #999999
	}

	.editor-btn {
		color: #8c7065;
		display: flex;
		align-items: center;
		padding-left: 90%;

		span {
			margin-left: 5px;
			cursor: pointer;
		}
	}
</style>