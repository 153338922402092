<!--
 * @Descripttion: 这是描述
 * @Date: 2022-04-26 11:16:28
 * @LastEditTime: 2022-09-20 10:49:39
-->
<!--
 * @Descripttion: 订单详情
 * @Date: 2022-03-22 09:31:16
 * @LastEditTime: 2022-04-26 11:16:00
-->
<template>
  <el-dialog :modelValue="dialogVisible" width="30%" :title='filter'>
    <el-form ref="formRef" :model="form" label-width="120px">
	  <el-form-item :label='$t("can_withdraw_amount")' prop="account_type">
			{{userinfo.balance}}
      </el-form-item>
      <el-form-item :label='$t("withdraw_type")' prop="account_type">
		<el-select v-model="form.account_type" clearable @change="changeType">
			<el-option v-for="(item,index) in withdraw_type" :label="item" :value="index"></el-option>
		</el-select>
      </el-form-item>
	  <el-form-item :label='$t("withdraw_account")' prop="status">
	  		<span><el-input v-model="form.account" readonly></el-input></span>
	  </el-form-item>
	  <el-form-item :label='$t("withdraw_amount")' prop="status">
	  		<span><el-input type="number" v-model="form.amount"></el-input></span>
	  </el-form-item>
      <el-form-item>
        <el-button @click="handleRest">{{$t("cancel")}}</el-button>
        <el-button type="primary" @click="handleSift">{{$t("submit")}}</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { watch, reactive, ref, onBeforeUpdate, computed } from 'vue'

import { useStore } from 'vuex'

import { saveWithdraw } from '@/api/users';

import notice from '@/utils/notice'

export default {
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
    },
    withdraw_type: {
      type: Object,
    },
	userinfo:{
	  type:Object
	}
  },
  emits: ['reloadList', 'update:dialogVisible'],
  setup(props, { emit }) {
    const store = useStore()
    let formLabelWidth = '100px'
    const formRef = ref(null)
    let form = ref({
      account_type: '',
	  account:'',
      amount: ''
    })
	
	let bankList = []
			
	const initBankList = ()=>{	
		props.userinfo.bank_list.forEach((item,index)=>{
			bankList[item.account_type] = item
			if(props.userinfo.bank_list.length == 1)
			{
				form.value.account_type = item.account_type + "";
				changeType(item.account_type);
			}
		})
	}

    watch(
      () => props.dialogVisible,
      (v) => {
		if(v)
		{
			form.value.amount = ""
			initBankList()
		}
        //更换父组件dialogVisible的值
        emit('update:dialogVisible', v)
      }
    )
    const handleSift = () => {
      saveWithdraw(form.value).then((response)=>{
		  notice.success(t("account_success"));
		  emit('reloadList', {})
		  emit('update:dialogVisible', false)
	  })
      //const siftForm = Object.assign({}, form)
    }
    const handleRest = () => {
      emit('update:dialogVisible', false)
    }
	const changeType = (val) =>{
		if(bankList[val]){
			console.log(bankList);
			form.value.account = bankList[val].account_number + "[" + bankList[val].account_name + "]"
		}
	}

    return {
      formLabelWidth,
      form,
      formRef,
      handleRest,
	  handleSift,
	  changeType
    }
  },
}
</script>

<style lang="scss">
</style>