<!--
 * @Descripttion: 这是描述
 * @Date: 2022-03-19 08:45:59
 * @LastEditTime: 2022-09-20 10:20:23
-->
<template>
  <el-config-provider :locale="locale">
    <router-view />
  </el-config-provider>
</template>

<script>
import { computed, onMounted, watch, getCurrentInstance  } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import config from '@/config'
import { routeFormatTag } from '@/utils/helper'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import en from 'element-plus/lib/locale/lang/en'
import { getLocale,setLocale } from './utils/localforage'
import { init } from '@/api/auth'

export default {
  name: 'App',
  setup() {
    const route = useRoute()
    const store = useStore()
    onMounted(() => { })

	const { proxy } = getCurrentInstance();

    getLocale().then((lang) => {
      if (lang) {
        store.commit('SET_LOCALE', lang)
        proxy.$i18n.locale = lang
		localStorage.setItem('lang',lang)
      }
	  else{
		init().then((res)=>{
			if(res.data.lang)
			{
				store.commit("SET_LOCALE", res.data.lang)
				proxy.$i18n.locale = res.data.lang
				setLocale(res.data.lang)
				localStorage.setItem('lang',res.data.lang)
			}
		})
	  }
    })

    watch(route, () => {
      store.commit('SET_BREADCRUMB', route.matched)
      if (route.name !== config.loginRouteName) {
        store.dispatch('openTagView', routeFormatTag(route))
      }
    })

    const locale = computed(() => {
      return store.getters.locale === 'zh-cn' ? zhCn : en
    })

    return {
      locale,
    }
  },
}
</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
}
body {
  margin: 0;
}
#app {
  height: 100%;
}
.el-button + .el-button {
  margin-left: 6px !important;
  margin-right: 6px !important;
}
.el-button {
  margin-left: 6px !important;
  margin-right: 6px !important;
}
</style>
