<template>
  <div class="page-header">
    <h3>{{$t("change_pwd")}}</h3>
    <p>{{$t("change_pwd_tip")}}</p>
  </div>
  <el-card style="margin:20px;padding:20px 100px">
    <el-form :model="form" :rules="rules" ref="formRef" label-width="200px">
      <el-form-item :label='$t("old_pwd")' prop="old_password">
        <el-input type="password"  v-model="form.old_password" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label='$t("new_pwd")' prop="password">
        <el-input type="Password" v-model="form.password" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label='$t("re_pwd")' prop="password_confirmation">
        <el-input type="Password" v-model="form.password_confirmation" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" plain @click="submitForm()">{{$t("submit")}}</el-button>
        <el-button plain @click="resetForm()">{{$t("reset")}}</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import config from '@/config'
import notice from '@/utils/notice'
import i18n from '@/lang'
import { changePassword } from '@/api/changePassword'

export default {
  name: "changePasswordPage",
  setup() {
    const store = useStore()
    const router = useRouter()
    const form = ref({
      old_password: null,
      password: null,
      password_confirmation: null
    })
	const { t } = i18n.global
    let confirmPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter your password again'))
      } else if (value !== form.value.password) {
        callback(new Error('Inconsistent password entered twice'))
      } else {
        callback()
      }
    }

    const rules = {
      old_password: [
        { required: true, message: t("please_input_your_old_password") },
        // { min: 6, max: 32 }
      ],
      password: [
        { required: true, message: t("please_enter_a_new_password") },
        { min: 6, max: 32, message: t("the_password_must_be_between_6_and_32_characters") }
      ],
      password_confirmation: [
        { required: true, message: t("please_enter_the_new_password_again") },
        { validator: confirmPassword, message: t("entered_passwords_differ") },
        { min: 6, max: 32, message: t("the_password_must_be_between_6_and_32_characters") }
      ]
    }

    const formRef= ref(null)

    const submitForm = () => {
      formRef.value.validate((valid) => {
        if (!valid) {
          return false
        }

        changePassword(form.value).then(() => {
          resetForm()
          notice.editSuccess(t("action_success"))
          store.dispatch("logoutHandle").then(() => {
            router.push({
              name: config.loginRouteName
            })
          }).catch(err => {
            console.log('logoutHandle: ', err)
          })
        })
      })
    }

    const resetForm = () => {
        formRef.value.resetFields()
    }

    return {
      rules,
      form,
      submitForm,
      resetForm,
      formRef,
    }
  },
}
</script>
<style lang="scss" scoped>
  .page-header {
    text-align: left;
    padding:20px;
    border-bottom: 1px solid #ebeef5;
    box-shadow:  0 2px 12px 0 rgba(0, 0, 0, 0.1);
    h3 {
      margin: 8px 0;
    }
    p {
      font-size:14px;
    }
  }
</style>