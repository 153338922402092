import http from '@/utils/http'
import config from '@/config'

export const login = ({ username, password }) => {
  return http.post('auth/login', {
    username,
    password
  })
}

export const logout = () => {
  return http.post('auth/logout')
}

export const init = () => {
  return http.get('init')
}