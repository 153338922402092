<!--
 * @Descripttion: 这是描述
 * @Date: 2022-04-26 11:16:28
 * @LastEditTime: 2022-09-20 10:49:39
-->
<!--
 * @Descripttion: 订单详情
 * @Date: 2022-03-22 09:31:16
 * @LastEditTime: 2022-04-26 11:16:00
-->
<template>
  <el-dialog :modelValue="dialogVisible" width="30%" :title='$t("add_user")'>
    <el-form ref="formRef" :model="form" label-width="120px">
		<el-form-item :label='$t("mobile")' prop="mobile" v-if="usemobile">
				<span><el-input v-model="form.mobile"></el-input></span>
		</el-form-item>
	  <el-form-item :label='$t("email")' prop="email" v-else>
	  		<span><el-input v-model="form.email"></el-input></span>
	  </el-form-item>
	  <el-form-item :label='$t("password")' prop="password">
	  	<span><el-input type="password" v-model="form.password"></el-input></span>
	  </el-form-item>
	  <el-form-item :label='$t("re_pwd")' prop="re_password">
	  	<span><el-input type="password" v-model="form.re_password"></el-input></span>
	  </el-form-item>
      <el-form-item :label='$t("bonus_grade")' prop="bonus_grade">
        <el-select v-model="form.bonus_grade" @change="changeGrade">
			<el-option v-for="(item,index) in user_grade"  :label="item" :value="index" :key="index"></el-option>
		</el-select>
      </el-form-item>
	  <el-form-item :label='$t("pay_balance")' prop="pay_balance">
	    {{form.pay_balance}}
	  </el-form-item>
      <el-form-item>
        <el-button @click="handleRest">{{$t("reset")}}</el-button>
        <el-button type="primary" @click="handleSift">{{$t("submit")}}</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { watch, reactive, ref, onMounted, onBeforeUpdate, computed } from 'vue'

import { useStore } from 'vuex'
import { addUser } from '@/api/users'
import notice from '@/utils/notice'
import i18n from '@/lang'
export default {
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
    },
    defaultParams: {
      type: Object,
    },
	user_grade:{
	  type:Object
	},
	bonus_amount:{
	  type:Object
	}
  },
  emits: ['reloadList', 'update:dialogVisible'],
  setup(props, { emit }) {
    const store = useStore()
    let formLabelWidth = '100px'
    const formRef = ref(null)
	const { t } = i18n.global
    let form = reactive({
      email: '',
      mobile: '',
      password: '',
      re_password: '',
	  bonus_grade:"",
	  pay_balance:0
    })
	const usemobile = ref(true)
	usemobile = computed(()=>store.getters.locale === 'zh-cn' ? true : false) 

    watch(
      () => props.dialogVisible,
      (v) => {
        //更换父组件dialogVisible的值
        emit('update:dialogVisible', v)
        if (v) {
          console.log('props.defaultParams', props.defaultParams)
          for (let i in props.defaultParams) {
            if (form.hasOwnProperty(i)) {
              form[i] = props.defaultParams[i]
            }
          }
        }
      }
    )
    const handleSift = () => {
		if(usemobile.value && !form.mobile)
		{
			notice.failed(t("please_input_mobile"))
			return;
		}
		else if(!usemobile.value && !form.email){
			notice.failed(t("please_input_emial"))
			return;
		}
		if(!form.password){
			notice.failed(t("please_input_pwd"))
			return;
		}
		if(!form.re_password){
			notice.failed(t("please_confirm_pwd"))
			return;
		}
		if(form.re_password !== form.password){
			notice.failed(t("passwords_do_not_match"))
			return;
		}
		
	  addUser(form).then(()=>{
		  notice.success(t("success") + '...')
		  const siftForm = Object.assign({}, form)
		  formRef.value.resetFields()
		  emit('reloadList', siftForm)
		  emit('update:dialogVisible', false)
	  }).catch((e)=>{
		  //notice.failed(t("fail") + '...')
	  })
    }
    const handleRest = () => {
      formRef.value.resetFields()
    }
	const changeGrade = (value)=>{
		if(value > 0){
			 form.pay_balance = props.bonus_amount[value - 1]
		}
		else{
			form.pay_balance = 0;
		}
	}
    //权限

    return {
      formLabelWidth,
      form,
      formRef,
      handleRest,
      handleSift,
	  usemobile,
	  changeGrade
    }
  },
}
</script>

<style lang="scss">
</style>