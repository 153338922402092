<template>
  <el-tabs type="border-card" @tab-click="getConfigData">
    <!-- 基本信息 -->
    <el-tab-pane :label="$t('withdraw_info')">
        <el-card v-if="account_type.bank">
            <template #header>
                <div class="card-header">
                <span>{{t("bank_card")}}:</span>
                </div>
            </template>
            <el-form  :model="form.bank">
                <el-form-item prop="value" :label="$t('name')" :label-width="formLabelWidth">
                    <el-input v-model="form.bank.account_name" :placeholder="$t('pleace_input_name')" />
                </el-form-item>
                <el-form-item prop="value" :label="$t('card_number')" :label-width="formLabelWidth">
                  <el-input v-model="form.bank.account_number" :placeholder="$t('pleace_input_cardno')" />
                </el-form-item>
                <el-form-item prop="value" :label="$t('bank_name')" :label-width="formLabelWidth">
                  <el-input v-model="form.bank.bank_name" :placeholder="$t('pleace_input_bankname')" />
                </el-form-item>
				  <div class="u-p-l-150 u-m-t-15" >
					<el-button type="primary" class="u-m-l-10" @click="saveBankInfo('bank',0)">{{ $t('save') }}</el-button>
				  </div>
            </el-form>
        </el-card>
        <el-card class="u-m-t-15" v-if="account_type.wechat">
        <template #header>
          <div class="card-header">
            <span>{{$t("wechat")}}:</span>
          </div>
        </template>
          <el-form  :model="form.wechat">
              <el-form-item prop="value" :label="$t('name')" :label-width="formLabelWidth">
                  <el-input v-model="form.wechat.account_name" :placeholder="$t('pleace_input_name')" />
              </el-form-item>
              <el-form-item prop="value" :label="$t('account')" :label-width="formLabelWidth">
                <el-input v-model="form.wechat.account_number" :placeholder="$t('pleace_input_account')" />
              </el-form-item>
          	  <div class="u-p-l-150 u-m-t-15">
          		<el-button type="primary" class="u-m-l-10" @click="saveBankInfo('wechat',1)">{{ $t('save') }}</el-button>
          	  </div>
          </el-form>
      </el-card>
        <el-card class="u-m-t-15" v-if="account_type.alipay">
            <template #header>
                <div class="card-header">
                <span>{{$t("alipay")}}:</span>
                </div>
            </template>
            <el-form  :model="form.alipay">
                <el-form-item prop="value" :label="$t('name')" :label-width="formLabelWidth">
                    <el-input v-model="form.alipay.account_name" :placeholder="$t('pleace_input_name')" />
                </el-form-item>
                <el-form-item prop="value" :label="$t('account')" :label-width="formLabelWidth">
                  <el-input v-model="form.alipay.account_number" :placeholder="$t('pleace_input_account')" />
                </el-form-item>
            	  <div class="u-p-l-150 u-m-t-15">
            		<el-button type="primary" class="u-m-l-10" @click="saveBankInfo('alipay',2)">{{ $t('save') }}</el-button>
            	  </div>
            </el-form>
        </el-card>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { reactive, ref } from '@vue/reactivity';
import notice from '@/utils/notice'
import { useStore } from 'vuex'
import i18n from '@/lang'
import {
	getBank,
	saveBank
} from '@/api/users'
export default {
name: 'config',
components: {
	
},
setup() {
	let account_type = ref({
		"bank":false,
		"wechat":false,
		"alipay":false
	})
	
    let form  = ref({
    	"bank":{},
    	"wechat":{},
    	"alipay":{}
    })
	const { t } = i18n.global
	const getBankInfo = () => {
	  getBank().then((response)=>{
		  if(response.data.bank.bank)
		  {
			  form.value.bank = response.data.bank.bank
		  }
		  if(response.data.bank.wechat)
		  {
		  			  form.value.wechat = response.data.bank.wechat
		  }
		  if(response.data.bank.alipay)
		  {
		  			  form.value.alipay = response.data.bank.alipay
		  }
		  if(response.data.account_type){
			  Object.keys(response.data.account_type).forEach((item,index)=>{
				  account_type.value[item] = true
			  })
		  }
	  })
	}
	const saveBankInfo = (type,a_type)=>{
		let data = form.value[type]
		data.account_type = a_type
		saveBank(data).then((response)=>{
			notice.success(t("action_success"))
			getBankInfo()
		})
	}
	getBankInfo();
    return {
        form,
		getBankInfo,
		saveBankInfo,
		account_type
    }
}
}
</script>

<style lang="scss" scoped>
.content-box{
		align-items: flex-start;
	}
	.img-box{
		position:relative;
	}
	.del-icon{
		color: red;
		background:#fff;
		font-size: 26px;
		border-radius:13px;
		position: absolute;
		top: -5px;
		right: -5px;
		cursor: pointer;
	}
	.el-link{
		font-size: 16px;
		vertical-align: top;
	}
.tips-span {
  padding: 0 0 20px 45px;
  color: #999999
}
.editor-btn {
  color: #8c7065;
  display: flex;
  align-items: center;
  padding-left: 90%;
  span {
    margin-left: 5px;
    cursor:pointer;
  }
}
</style>