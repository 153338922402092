<!--
 * @Descripttion: 这是描述
 * @Date: 2022-04-26 11:16:28
 * @LastEditTime: 2022-09-20 10:49:39
-->
<!--
 * @Descripttion: 订单详情
 * @Date: 2022-03-22 09:31:16
 * @LastEditTime: 2022-04-26 11:16:00
-->
<template>
  <el-dialog :modelValue="dialogVisible" width="280px" :title='$t("send_input_vercode")'>
    <el-form ref="formRef" :model="form">
      <el-form-item prop="type" >
		<el-image v-if="captchaImg" :src="captchaImg" style="width: 150px; height: 63px;"></el-image>
		<el-button icon="RefreshRight" @click="getCode"></el-button>
      </el-form-item>
      <el-form-item  prop="ver_code">
		<el-input v-model="form.captcha" :placeholder='$t("please_input_piccode")'></el-input>
      </el-form-item>
      <el-form-item>
		<el-button style="width: 100% !important;" type="primary" @click="sendCode">{{$t("send")}}</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { watch, reactive, ref, onMounted, onBeforeUpdate, computed } from 'vue'

import { useStore } from 'vuex'

import { getCaptcha,sendCaptcha } from '@/api/users'

import { RefreshRight } from '@element-plus/icons-vue'

export default {
  components: {},
  props: {
	dialogVisible:{
		type:Boolean
	},
	data:{
		type:Object
	}
  },
  emits: ['reloadList', 'update:dialogVisible'],
  setup(props, { emit }) {
    const store = useStore()
    const formRef = ref(null)
	let captchaImg = ref("")
	let form = ref({
		"scene":"reset",
		"captcha":"",
		"captcha_key":"",
		//"origin_key":"",
		"type" : ""		
	})

    watch(
      () => props.dialogVisible,
      (v) => {
		getCode();
		form.value.captcha = ""
        //更换父组件dialogVisible的值
        emit('update:dialogVisible', v)
      }
    )
	
	const getCode = ()=>{
		getCaptcha().then((response) => {
						  captchaImg.value = response.data.img
						  form.value.captcha_key = response.data.key
						  //form.value.origin_key = response.data.origin_key
		}).catch(()=>{
			
		})
	}
    
    const sendCode = () => {
      //formRef.value.resetFields()
      //const siftForm = Object.assign({}, form)
      //emit('reloadList', siftForm)
	   form.value.type = props.data.type
	    sendCaptcha(form.value).then((response)=>{
			emit('reloadList', response.data)
			emit('update:dialogVisible', false)
		}).catch(()=>{
			getCode();
		})
    }

    return {
      form,
      formRef,
	  sendCode,
	  getCode,
	  form,
	  captchaImg
    }
  },
}
</script>

<style lang="scss">
</style>