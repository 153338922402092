/*
 * @Descripttion: 这是描述
 * @Date: 2022-04-24 15:54:31
 * @LastEditTime: 2022-05-07 09:36:29
 */
import { reactive, watch } from 'vue'

export function tableDefaultData() {
    const table = reactive({
        data: [],
        pagination: {
            currentPage: 1,
            pageSize: 15,
            total: 0,
            from: 1,
            lastPage: 1,
            to: 1
        },
        nowRow: {
            index: 0,
            row: {}
        },
        queryParams: {},
        loading: false
    })

    watch(() => table.queryParams, () => {
        table.pagination.currentPage = 1
    },{deep:true})

    table.getQueryParams = () => {
        return {...table.queryParams, page: table.pagination.currentPage }
    }

    return table
}


export function tableDataFormat(response, table) {
    let resdata = response.data.data
    if (resdata.length > 0) {
        const keys = Object.keys(resdata[0])
        resdata.forEach(item => {
            for (let i = 0; i < keys.length; i++) {
                if (item[keys[i]] === '' || item[keys[i]] === null) {
                    item[keys[i]] = '-'
                }
            }
        })
    }
    table.data = resdata

    let meta = {}
    if (response.data.hasOwnProperty(meta)) {
        meta = response.data.meta;
    } else {
        meta = {
            current_page: response.data.current_page,
            per_page: response.data.per_page,
            total: response.data.total,
            from: response.data.from,
            last_page: response.data.last_page,
            to: response.data.to,
        }
    }

    table.pagination = {
        currentPage: meta.current_page,
        pageSize: meta.per_page,
        total: meta.total,
        from: meta.from,
        lastPage: meta.last_page,
        to: meta.to
    }

    table.loading = false
}