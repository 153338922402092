import { loadPermissions } from '@/api/permission'

const state = {
  permissions: [],
  lang:"",
  distribution_type:""
}

const getters = {
  permissions: state => state.permissions,
  hasPermission: state => permissionName =>{
    return  state.permissions.indexOf(permissionName) >= 0
  },
  distribution_type: state => state.distribution_type
}

const mutations = {
  SET_PERMISSIONS (state, permissions) {
    state.permissions = permissions
  },
  SET_DISTRIBUTIONTYPE (state, specificSystemData) {
    state.distribution_type = specificSystemData
  },
}

const actions = {
  hasAnyPermission ({ commit }, permissions) {
    let object = {}
    permissions.forEach(permission => {
      object[permission] = state.permissions.indexOf(permission) >= 0
    })
    return object
  },
  loadPermissions ( { commit }) {
    return loadPermissions()
      .then(response => {
        if (response && response.data && response.data.permission) {
          commit('SET_PERMISSIONS', response.data.permission)
        }
		if (response && response.data && response.data.lang) {
		  commit('SET_LOCALE', response.data.lang)
		}
		if (response && response.data && response.data.distribution_type) {
		  commit('SET_DISTRIBUTIONTYPE', response.data.distribution_type)
		}
        Promise.resolve()
      })
      .catch( error => {
        Promise.reject(error)
      })
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}