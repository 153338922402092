<!--
 * @Descripttion: 这是描述
 * @Date: 2022-04-21 17:25:01
 * @LastEditTime: 2022-04-21 17:38:42
-->
<template>
  <el-pagination
    class="u-m-t-30"
    v-model:currentPage="currentPage"
    v-model:page-size="pageSize4"
    :page-sizes="[100, 200, 300, 400]"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="400"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
</template>
<script>
import { watch, ref } from 'vue'

export default {
  name: 'page',
  props: {
      value:{
          type:[String,Number]
      }
  },
  setup(props, { emit }) {
    const currentPage = ref(1)
    const pageSize4 = ref(100)
    const background = ref(false)
    const disabled = ref(false)
    const handleCurrentChange=()=>{

    }
    const handleSizeChange=()=>{

    }
    return {
      currentPage,
      pageSize4,
      background,
      disabled,
      handleCurrentChange,
      handleSizeChange
    }
  },
}
</script>