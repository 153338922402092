<!--
 * @Descripttion: 销售报表
 * @Date: 2022-03-19 15:28:23
 * @LastEditTime: 2022-05-31 09:14:50
-->
<template>
  <div>
    <el-card>
      <div class="u-flex u-100 u-m-b-20">
        <div>
          <span class="page-title">{{$t("withdraw_log")}}</span>
        </div>
        <div class="u-flex u-m-l-auto u-row-center">
          <el-button type="text" @click="reflashData" >{{$t("refresh")}}</el-button>
          <el-button type="text" @click="showSiftData">{{ $t("filter")}}</el-button>
        </div>
      </div>
      <el-table
        :data="table.data"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="amount" :label='$t("withdraw_amount")' width="180" />
		<el-table-column prop="account_type" :label='$t("withdraw_type")' width="180" />
		<el-table-column prop="account" :label='$t("withdraw_account")' width="180" />
        <el-table-column prop="status" :label='$t("status")' width="100" />
		<el-table-column prop="refuse_reason" :label='$t("memo")' width="100" />
        <el-table-column prop="created_at" :label='$t("time")' />
      </el-table>
      <!-- 分页 -->
      <el-pagination
        class="pagination-right"
        @current-change="requestData"
        v-model:currentPage="table.pagination.currentPage"
        :page-size="table.pagination.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="table.pagination.total"
      >
      </el-pagination>
    </el-card>
    <withdraw-sift
      v-model:dialogVisible="dialogSiftVisible"
      @reloadList="requestData"
      :defaultParams="table.queryParams"
	  :withdraw_type = "withdraw_type"
	  :withdraw_status = "withdraw_status"
    ></withdraw-sift>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/Page.vue'
import { watch, ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { tableDefaultData, tableDataFormat } from '@/utils/table'
import { getWithdrawList } from '@/api/list'
import withdrawSift from './components/withdraw-sift'
import { useRouter } from 'vue-router'

export default {
  name: 'sales',
  components: {
    Pagination,
	withdrawSift
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    let dialogSiftVisible = ref(false)
    //加载列表数据
    let table = tableDefaultData()
	let withdraw_type = ref({})
	let withdraw_status = ref({})

    if (parseInt(router.currentRoute.value.params.status)) {
      table.queryParams.status = parseInt(router.currentRoute.value.params.status)
    }
	if (parseInt(router.currentRoute.value.params.account_type)) {
	  table.queryParams.account_type = parseInt(router.currentRoute.value.params.account_type)
	}
	if (router.currentRoute.value.params.start_time) {
	  table.queryParams.start_time = router.currentRoute.value.params.start_time
	}
	if (parseInt(router.currentRoute.value.params.end_time)) {
	  table.queryParams.end_time = parseInt(router.currentRoute.value.params.end_time)
	}
    const requestData = (params) => {
      if (params) {
        Object.assign(table.queryParams, params)
      }
      table.loading = true
      console.log('table.getQueryParams()',table.getQueryParams());
      getWithdrawList(table.getQueryParams()).then((response) => {
        table.loading = false

        tableDataFormat(response, table)
		withdraw_type.value = response.data.withdraw_type
		withdraw_status.value = response.data.withdraw_status
      })
    }
    requestData()
    const showSiftData = () => {
      dialogSiftVisible.value = true
    }
    const reflashData = () => {

      Object.keys(table.queryParams).forEach(key => (table.queryParams[key] = ''));
      console.log(table.queryParams);
      requestData()
    }
    return {
      dialogSiftVisible,
      table,
      requestData,
      showSiftData,
      reflashData,
	  withdraw_status,
	  withdraw_type
    }
  },
}
</script>

<style lang="scss" scoped>
.situationboard {
  display: flex;
  position: relative;
  height: 140px;
  min-width: 720px;
  width: calc(100% - 208px);
  .situation {
    text-align: left;
    line-height: 28px;
    min-width: 130px;
    padding: 24px 16px;
    .name {
      font-size: 14px;
    }
    .prev,
    .prevnum {
      color: #969799;
      font-size: 10px;
    }
  }
}
</style>