/*
 * @Descripttion: 这是描述
 * @Date: 2022-03-19 09:23:01
 * @LastEditTime: 2022-03-22 16:55:57
 */
import referralUsersPage from "@/views/referralUsers"

export default [
    {
      name: 'referralUsers',
      path: '/referralUsers',
      meta: {
        id:'2',
        icon:'Share',
        title: 'referralUsers',
        name:"referral_user",
        cache: true,
		permission:"referral-user"
      },
      component: referralUsersPage
    },
  ]
  