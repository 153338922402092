<!--
 * @Descripttion: 这是描述
 * @Date: 2022-05-16 17:45:41
 * @LastEditTime: 2022-07-07 17:47:11
-->
<template>
  <div>
    <!-- <h1>欢迎使用{{appName}}</h1> -->
    <el-card class="u-m-b-20">
      <div>
        <div class="u-flex" v-loading="isTopLoading">
          <div class="statistics">
            <div class="u-flex u-col-top">
              {{$t("num_of_user")}}
			  <span class="num">
			  {{
                statisticsTop.totol_user_count
              }}
			  </span>
            </div>
            <div
              style=" text-align: center; margin-top: 20px;  width:300px; margin-left:auto; margin-right:auto;"
            >
              <div id="userTotal" class="totalPie"></div>
            </div>
			<div class="btn-box">
				
			</div>
          </div>
          <div class="statistics">
            <div class="u-flex u-col-top">
              {{$t("total_bonus_count_line")}}
			  <span class="num">
			  {{
                statisticsTop.total_bonus_count
              }}
			  </span>
            </div>
            <div
              style="display: flex; align-items: flex-start; margin-top: 20px; width:300px; margin-left:auto; margin-right:auto;"
            >
              <div id="countOrderTotal" v-show="bonus_type == '0'" class="totalPie"></div>
			  <div id="countBonusTotal" v-show="bonus_type == '1'" class="totalPie"></div>
            </div>
			<div class="btn-box">
				<el-radio-group v-model="bonus_type">
					<el-radio label="0" class="radio">{{$t("by_order_type")}}</el-radio>
					<el-radio label="1" class="radio">{{$t("by_bonus_type")}}</el-radio>
				</el-radio-group>
			</div>
          </div>
          <div class="statistics">
            <div class="u-flex u-col-top">
              {{$t("total_bonus_amount_line")}}
			  <span class="num">
			  {{
                statisticsTop.total_bonus_amount
              }}
			  </span>
            </div>
            <div
              style="display: flex; align-items: flex-start; margin-top: 20px; width:300px; margin-left:auto; margin-right:auto;"
            >
              <div id="amountOrderTotal" v-show="amount_type == '0'" class="totalPie"></div>
			  <div id="amountBonusTotal" v-show="amount_type == '1'" class="totalPie"></div>
            </div>
			<div class="btn-box">
				<el-radio-group v-model="amount_type">
					<el-radio label="0" class="radio">{{$t("by_order_type")}}</el-radio>
					<el-radio label="1" class="radio">{{$t("by_bonus_type")}}</el-radio>
				</el-radio-group>
			</div>
          </div>
        </div>
      </div>
    </el-card>
    <el-card>
      <div style="position: relative">
        <el-tabs
          v-model="by_type"
          class="demo-tabs"
          @tab-click="requestData"
          :before-leave="TabsBeforeLeave"
        >
          <el-tab-pane :label='$t("by_order_type")' name="order_type"></el-tab-pane>
          <el-tab-pane :label='$t("by_bonus_type")' name="bonus_type"></el-tab-pane>
        </el-tabs>
		<el-date-picker
		  class="date-picker"
		  v-model="dateRangeValue"
		  type="daterange"
		  unlink-panels
		  value-format="YYYY-MM-DD hh:mm:ss"
		  :start-placeholder="$t('start_time')"
		  :end-placeholder="$t('end_time')"
		  :range-separator='$t("to")'
		  @change="dateRangeChange"
		  :disabled-date="disabledDate"
		  :shortcuts="shortcuts"
		/>
      </div>
      <div
        style="display: flex; align-items: flex-start; margin-top: 20px"
        v-loading="isChartsLoading"
      >
        <div id="userMain" class="main"></div>
      </div>
	  <div
        style="display: flex; align-items: flex-start; margin-top: 20px"
        v-loading="isChartsLoading"
      >
        <div id="bonusMain" class="main"></div>
      </div>
	  <div
        style="display: flex; align-items: flex-start; margin-top: 20px"
        v-loading="isChartsLoading"
      >
        <div id="amountMain" class="main"></div>
      </div>
    </el-card>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { useStore } from 'vuex'
import { ref, nextTick } from 'vue-demi'
import { getMainStatTop, getMainStatDown } from '@/api/dashboard.js'
import { dateFormat } from '@/utils/helper'
import i18n from '@/lang'

export default {
  name: 'adminDashboard',
  setup() {
	const { t } = i18n.global
    const end_date_stamp =
      new Date(new Date().toLocaleDateString()).getTime() - 1000
    const start_date_stamp = end_date_stamp - 3600 * 1000 * 24 * 7 + 1000
    const endDateString = dateFormat(
      'YYYY-mm-dd HH:MM:SS',
      new Date(end_date_stamp)
    )
    const StartDateString = dateFormat(
      'YYYY-mm-dd HH:MM:SS',
      new Date(start_date_stamp)
    )

    const store = useStore()
    const by_type = ref('order_type')
    const isChartsLoading = ref(false)
    const isTopLoading = ref(false)
    const dateRangeValue = ref([StartDateString,endDateString])
	let bonus_type = ref("0")
	let amount_type = ref("0")
    const shortcuts = [
      {
        text: t('near_7_days'),
        value: () => {
          const end = new Date(endDateString)
          const start = new Date(StartDateString)
          return [start, end]
        },
      },
      {
        text: t('near_15_days'),
        value: () => {
          const end = new Date(endDateString)
          const start = new Date(end_date_stamp - 3600 * 1000 * 24 * 15 + 1000)
          return [start, end]
        },
      },
      {
        text: t('near_30_days'),
        value: () => {
          const end = new Date(endDateString)
          const start = new Date(end_date_stamp - 3600 * 1000 * 24 * 30 + 1000)
          return [start, end]
        },
      },
      {
        text: t('near_1_year'),
        value: () => {
          const end = new Date(endDateString)
          const start = new Date(end_date_stamp - 3600 * 1000 * 24 * 365 + 1000)
          return [start, end]
        },
      },
    ]
    const statisticsTop = ref({
		totol_user_count:0,
		total_bonus_count:0,
		total_bonus_amount:0,
		totol_user_count_pie:{},
		total_bonus_count_order_pie:{},
		total_bonus_count_bonus_pie:{},
		total_bonus_amount_order_pie:{},
		total_bonus_amount_bonus_pie:{}
    })
    const statisticsCharts = ref({
      amount:[],
	  bonus:[],
	  user:[]
    })
    const disabledDate = (v) => {
      // 日期禁止选择今天之后的日期
      return v.getTime() > Date.now()
    }
    const dateRangeChange = () => {
      dateRangeValue.value = [
        dateRangeValue.value[0].slice(0, 11) + '00:00:00',
        dateRangeValue.value[1].slice(0, 11) + '23:59:59',
      ]
      requestData()
    }
    const TabsBeforeLeave = () => {
      return !isChartsLoading.value
    }
    const requestTopData = () => {
      isTopLoading.value = true
       getMainStatTop()
        .then((result) => {
          isTopLoading.value = false
		  console.log(result.data);
          statisticsTop.value = result.data
		  initTopCharts()
        })
        .catch((err) => {}) 
    }
    const requestData = (tab) => {
      let typevalue=by_type.value
      if(tab){
        console.log(tab.index);
        typevalue=tab.index==0?'order_type':'bonus_type'
      }
      if (isChartsLoading.value) return
      isChartsLoading.value = true
      getMainStatDown({
        begin_time: dateRangeValue.value[0],
        end_time: dateRangeValue.value[1],
        by_type: typevalue,
      })
        .then((result) => {
          isChartsLoading.value = false
          statisticsCharts.value = result.data
		  console.log(statisticsCharts.value)
          initCharts()
        })
        .catch((err) => {})
    }
	const initTopCharts = () => {
	  nextTick(() => {
	    let userData = statisticsTop.value.totol_user_count_pie
		let countOrderData = statisticsTop.value.total_bonus_count_order_pie
		let countBonusData = statisticsTop.value.total_bonus_count_bonus_pie
		let amountOrderData = statisticsTop.value.total_bonus_amount_order_pie
		let amountBonusData = statisticsTop.value.total_bonus_amount_bonus_pie
		
		let userChartsLegend = Object.values(userData)
		let countOrderChartsLegend = Object.values(countOrderData)
		let countBonusChartsLegend = Object.values(countBonusData)
		let amountOrderChartsLegend = Object.values(amountOrderData)
		let amountBonusChartsLegend = Object.values(amountBonusData)
		
		userChartsLegend.forEach((item,index)=>{
			userChartsLegend[index] = t(item.name)
		})
				
		countOrderChartsLegend.forEach((item,index)=>{
			countOrderChartsLegend[index] = t(item.name)
		})
		countBonusChartsLegend.forEach((item,index)=>{
			countBonusChartsLegend[index] = t(item.name)
		})
		amountOrderChartsLegend.forEach((item,index)=>{
			amountOrderChartsLegend[index] = t(item.name)
		})
		amountBonusChartsLegend.forEach((item,index)=>{
			amountBonusChartsLegend[index] = t(item.name)
		})
		
		let userSeries = statisticsTop.value.totol_user_count_pie
		let countOrderSeries = statisticsTop.value.total_bonus_count_order_pie
		let countBonusSeries = statisticsTop.value.total_bonus_count_bonus_pie
		let amountOrderSeries = statisticsTop.value.total_bonus_amount_order_pie
		let amountBonusSeries = statisticsTop.value.total_bonus_amount_bonus_pie
		
	    echarts.init(document.getElementById('userTotal')).dispose()
		echarts.init(document.getElementById('countOrderTotal')).dispose()
		echarts.init(document.getElementById('countBonusTotal')).dispose()
		echarts.init(document.getElementById('amountOrderTotal')).dispose()
		echarts.init(document.getElementById('amountBonusTotal')).dispose()
		
	    var userChart = echarts.init(document.getElementById('userTotal'))
		var countOrderChart = echarts.init(document.getElementById('countOrderTotal'))
		var countBonusChart = echarts.init(document.getElementById('countBonusTotal'))
		var amountOrderChart = echarts.init(document.getElementById('amountOrderTotal'))
		var amountBonusChart = echarts.init(document.getElementById('amountBonusTotal'))
		
	    // 绘制图表
	    userChart.setOption({
	      title: {},
	      tooltip: {formatter:"{b}:{c}%"},
		  legend:{data:userChartsLegend,icon:"circle"},
	      series: {name:'',type:'pie',data:userSeries,top:25,label:{position:"inner",formatter:'{c}%',color:'#ffffff'}}
	    })
		countOrderChart.setOption({
		  title: {},
		  tooltip: {formatter:"{b}:{c}%"},
		  legend:{data:countOrderChartsLegend,icon:"circle"},
		  series: {name:'',type:'pie',data:countOrderSeries,top:25,label:{position:"inner",formatter:'{c}%',color:'#ffffff'}}
		})
		countBonusChart.setOption({
		  title: {},
		  tooltip: {formatter:"{b}:{c}%"},
		  legend:{data:countBonusChartsLegend,icon:"circle"},
		  series: {name:'',type:'pie',data:countBonusSeries,top:25,label:{position:"inner",formatter:'{c}%',color:'#ffffff'}}
		})
		amountOrderChart.setOption({
		  title: {},
		  tooltip: {formatter:"{b}:{c}%"},
		  legend:{data:amountOrderChartsLegend,icon:"circle"},
		  series: {name:'',type:'pie',data:amountOrderSeries,top:25,label:{position:"inner",formatter:'{c}%',color:'#ffffff'}}
		})
		amountBonusChart.setOption({
		  title: {},
		  tooltip: {formatter:"{b}:{c}%"},
		  legend:{data:amountBonusChartsLegend,icon:"circle"},
		  series: {name:'',type:'pie',data:amountBonusSeries,top:25,label:{position:"inner",formatter:'{c}%',color:'#ffffff'}}
		})
	  })
	} 
     const initCharts = () => {
      nextTick(() => {
        let userData = statisticsCharts.value.user
		let bonusData = statisticsCharts.value.bonus
		let amountData = statisticsCharts.value.amount
		
		let userChartsLegend = Object.keys(userData)
		let bonusChartsLegend = Object.keys(bonusData)
		let amountChartsLegend = Object.keys(amountData)
		
		userChartsLegend.forEach((item,index)=>{
			userChartsLegend[index] = t(item)
		})
		
		bonusChartsLegend.forEach((item,index)=>{
			bonusChartsLegend[index] = t(item);
		})
		amountChartsLegend.forEach((item,index)=>{
			amountChartsLegend[index] = t(item);
		})
		
		let userSeries = []
		let bonusSeries = []
		let amountSeries = []
		
		Object.keys(userData).forEach((key,index)=>{
			userSeries.push({
				data:Object.values(userData[key]),
				type: 'line',
				symbol:"circle",
				label:{"show":true,color:'inherit'},
				name:t(key)
			})
		})
		
		Object.keys(bonusData).forEach((key,index)=>{
			bonusSeries.push({
				data:Object.values(bonusData[key]),
				type: 'line',
				symbol:"circle",
				label:{"show":true,color:'inherit'},
				name:t(key)
			})
		})
		
		Object.keys(amountData).forEach((key,index)=>{
			amountSeries.push({
				data:Object.values(amountData[key]),
				type: 'line',
				symbol:"circle",
				label:{"show":true,color:'inherit'},
				name:t(key)
			})
		})
		
        let chartsKeys = Object.values(statisticsCharts.value.date)
		
        echarts.init(document.getElementById('userMain')).dispose()
		echarts.init(document.getElementById('bonusMain')).dispose()
		echarts.init(document.getElementById('amountMain')).dispose()
		
        var userChart = echarts.init(document.getElementById('userMain'))
		var bonusChart = echarts.init(document.getElementById('bonusMain'))
		var amountChart = echarts.init(document.getElementById('amountMain'))

        // 绘制图表
        userChart.setOption({
          title: {
            text: '',
          },
          tooltip: {
			  trigger: 'axis'
		  },
		  legend:{
			   data:userChartsLegend,
			   bottom:0
		  },
          xAxis: {
            type: 'category',
            boundaryGap: ["5%",'5%'],
            data: chartsKeys,
          },
          yAxis: {
            type: 'value',
			boundaryGap: ["0%",'5%'],
			axisLine:{show:true}
          },
          series: userSeries,
        })
		
		bonusChart.setOption({
		  title: {
		    text: '',
		  },
		  tooltip: {
			  trigger: 'axis'
		  },
		  legend:{
			  data:bonusChartsLegend,
			  bottom:0
		  },
		  xAxis: {
		    type: 'category',
		    boundaryGap: ["5%",'5%'],
		    data: chartsKeys
		  },
		  yAxis: {
		    type: 'value',
			boundaryGap: ["0%",'5%'],
			axisLine:{show:true}
		  },
		  series: bonusSeries,
		})
		
		amountChart.setOption({
		  title: {
		    text: '',
		  },
		  tooltip: {
			  trigger: 'axis'
		  },
		  legend:{
			  data:amountChartsLegend,
			  bottom:0
		  },
		  xAxis: {
		    type: 'category',
		    boundaryGap: ["5%",'5%'],
		    data: chartsKeys,
		  },
		  yAxis: {
		    type: 'value',
			boundaryGap: ["0%",'5%'],
			axisLine:{show:true}
		  },
		  series: amountSeries,
		})
      })
    } 
    requestTopData()
    requestData()

    return {
      appName: store.state.env.APP_FULL_NAME || '后台管理系统',
      by_type,
      shortcuts,
      dateRangeValue,
      statisticsTop,
      isTopLoading,
      isChartsLoading,
      statisticsCharts,
      requestData,
      //initCharts,
      disabledDate,
      TabsBeforeLeave,
      dateRangeChange,
	  bonus_type,
	  amount_type
    }
  },
  mounted() {
    // 基于准备好的dom，初始化echarts实例
  },
}
</script>
<style lang="scss" scoped>
.statistics {
  padding: 20px;
  width: 30%;
  background: #fff;
  margin-right: 30px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: var(--el-card-border-radius);
  .money {
    margin-top: 10px;
  }
  .ratio {
    margin-left: 10px;
    color: rgb(179, 58, 58);
    font-size: 12px;
  }
}
.main {
  width: 1200px;
  height: 500px;
}
.totalPie{
	width: 300px;
	height: 250px;
}
::v-deep(.date-picker) {
  position: absolute;
  right: 20px;
  top: 0;
}
.top-ranking {
  list-style-type: none;
  line-height: 40px;
  padding: 0 10px 0 0;
  .rankItem {
    display: flex;
    align-items: center;
    &:first-child .top-ranking-index{
       background: rgb(2,135,251);
    }
    &:nth-child(2) .top-ranking-index{
       background: rgb(254,63,25);
    }
    &:nth-child(3) .top-ranking-index{
       background: rgb(249,182,22);
    }
    .top-ranking-index {
      display: inline-block;
      border-radius: 34px;
      width: 17px;
      height: 17px;
      text-align: center;
      line-height: 18px;
      background: rgb(192, 192, 192);
      color: white;
      font-size: 12px;
    }
    .top-ranking-name {
      width: 200px;
      margin: 0 10px;
      flex-basis: 200px;
    }
    .top-ranking-num {
      font-size: 20px;
      color: red;
      text-align: right;
      flex: 1;
    }
    li:nth-child(1) {
      .top-ranking-index {
        background: rgb(61, 106, 233);
      }
    }
    li:nth-child(2) {
      .top-ranking-index {
        background: rgb(254, 64, 27);
      }
    }
    li:nth-child(3) {
      .top-ranking-index {
        background: rgb(249, 182, 22);
      }
    }
  }
}
.btn-box{
	margin-top: 10px;
	display: flex;
	height:40px;
	justify-content:center;
	.radio{
		border: solid 1px #409eff;
		text-align: center;
		padding:2px 10px;
		border-radius: 5px;
	}
}
.u-col-top{
	text-align: center;
	background-color: #89a3fe;
	font-size: 15px;
	color:#fff;
	border-radius: 5px;
	display: block;
	padding: 30px 0 30px 0;
	font-weight: bold;
	span{
		padding-left: 10px;
	}
}
</style>