<!--
 * @Descripttion: 这是描述
 * @Date: 2022-04-26 11:16:28
 * @LastEditTime: 2022-09-20 10:49:39
-->
<!--
 * @Descripttion: 订单详情
 * @Date: 2022-03-22 09:31:16
 * @LastEditTime: 2022-04-26 11:16:00
-->
<template>
<div>
  <el-dialog :modelValue="dialogVisible" width="400px" :title="title">
    <el-form ref="formRef" :model="form">
      <el-form-item prop="type" v-if="step == 0">
		<el-radio-group v-model="form.type">
			<el-radio label="mobile" v-if="userinfo.mobile">{{$t("short_mobile")}}：{{userinfo.mobile}}</el-radio>
			<el-radio label="email"  v-if="userinfo.email">{{$t("short_email")}}：{{userinfo.email}}</el-radio>
		</el-radio-group>
      </el-form-item>
      <el-form-item  prop="ver_code" v-if="step == 0">
		<span><el-input v-model="form.verCode" :placeholder='$t("please_input_vercode")'></el-input></span>
		<span>
			<el-button v-if="downTime > 0">{{downTime}}s</el-button>
			<el-button v-else @click="sendCode" >
				{{$t("send_input_vercode")}}
			</el-button>
		</span>
      </el-form-item>
	  <el-form-item  prop="pwd" v-if="step == 1">
	  		<span><el-input type="password" v-model="form.password" :placeholder='$t("new_pwd")'></el-input></span>
	  </el-form-item>
	  <el-form-item  prop="re_pwd" v-if="step == 1">
	  		<span><el-input type="password" v-model="form.repassword" :placeholder='$t("re_pwd")'></el-input></span>
	  </el-form-item>
	  <el-form-item v-if="step == 1">
		  <span class="tips-span">{{$t("pwd_tip")}}</span>
	  </el-form-item>
      <el-form-item>
		<el-button v-if="step != 0" type="primary" @click="preStep">{{$t("pre_step")}}</el-button>
        <el-button v-if="step == 0" type="primary" @click="nextStep">{{$t("next_step")}}</el-button>
		<el-button v-if="step == 1" type="primary" @click="changePwd">{{$t("submit")}}</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
  <ver-code
    v-model:dialogVisible="dialogSiftVisible"
    @reloadList="requestData"
	:data = "form"
  ></ver-code>
</div>  
</template>

<script>
import { watch, reactive, ref, onMounted, onBeforeUpdate, computed } from 'vue'

import { useStore } from 'vuex'

import verCode from './ver_code'
import i18n from '@/lang'
import notice from '@/utils/notice'
import { changePayPwd } from '@/api/changePassword';

export default {
  components: {
	  verCode
  },
  props: {
	dialogVisible:{
		type:Boolean
	},
    type: {
      type: String,
    },
	userinfo:{
	  type:Object
	},
  },
  emits: ['reloadList', 'update:dialogVisible'],
  setup(props, { emit }) {
	const { t } = i18n.global
    const store = useStore()
    let formLabelWidth = '100px'
    const formRef = ref(null)
	let title = ref("")
	let step = ref(0)
	let dialogSiftVisible = ref(false)
	let downTime = ref(0)
	let timer = ref({})
	if(props.pwd_type == 'password')
	{
		title.value = t("set_pwd")
	}
	else{
		title.value = t("set_paypwd")
	}
    let form = reactive({
	  type:'mobile',
      verCode: '',
      password: '',
      repassword: ''
    })
    onMounted((params) => {
      // console.log('dialogCreate mounted')
    })
	const requestData = (params) => {
		if(params.second)
		{
			downTime.value = params.second
			timer.value = setInterval(function(){
				if(downTime.value > 0)
				{
					downTime.value --
				}
				else{
					clearInterval(timer.value)
				}
			},1000);
		}
	}
    watch(
      () => props.dialogVisible,
      (v) => {
        //更换父组件dialogVisible的值
        emit('update:dialogVisible', v)
      }
    )
    const nextStep = () => {
		if(!form.type){
			notice.failed(t("please_select_the_verification_method"));
			return;
		}
		if(!form.verCode){
			notice.failed(t("please_enter_the_verification_code"))
			return;
		}
      step.value ++
      //const siftForm = Object.assign({}, form)
      //emit('reloadList', siftForm)
      //emit('update:dialogVisible', false)
    }
    const preStep = () => {
		step.value -- 
      //formRef.value.resetFields()
      //const siftForm = Object.assign({}, form)
      //emit('reloadList', siftForm)
    }
	const sendCode = ()=>{
		if(!form.type)
		{
			notice.failed(t("please_select_the_verification_method"));
		}
		else{
			dialogSiftVisible.value = true
		}
	}
    const changePwd = ()=>{
		if(!form.password){
			notice.failed(t("please_enter_a_new_password"))
			return
		}
		if(!form.repassword){
			notice.failed(t("please_confirm_the_new_password"))
			return
		}
		if(form.password != form.repassword)
		{
			notice.failed(t("the_two_passwords_are_different"))
			return
		}
		changePayPwd(form).then((response) => {
			  //loading.value = false
			  notice.success(t("action_success"))
				emit('reloadList', {})
				emit('update:dialogVisible', false)
		  }).catch(()=>{
			  //loading.value = false
		  })
	}

    return {
      form,
      formRef,
      nextStep,
      preStep,
	  title,
	  step,
	  dialogSiftVisible,
	  requestData,
	  sendCode,
	  changePwd,
	  downTime
    }
  },
}
</script>

<style lang="scss">
</style>