/*
 * @Descripttion: 这是描述
 * @Date: 2022-03-19 09:23:01
 * @LastEditTime: 2022-03-22 16:55:57
 */
import recordsPage from "@/views/records"

export default [
    {
      name: 'records',
      path: '/records',
      meta: {
        id:'1',
        icon:'HelpFilled',
        title: 'records',
        name:"bonus_log",
        cache: true,
		permission:"records"
      },
      component: recordsPage
    },
  ]
  