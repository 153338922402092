<!--
 * @Descripttion: 销售报表
 * @Date: 2022-03-19 15:28:23
 * @LastEditTime: 2022-05-31 09:14:50
-->
<template>
  <div>
    <el-card>
      <div class="u-flex u-100 u-m-b-20">
        <div>
          <span class="page-title">{{$t("agent_purchase_logs")}}</span>
        </div>
        <div class="u-flex u-m-l-auto u-row-center">
          <el-button type="text" @click="reflashData" >{{$t("refresh")}}</el-button>
          <el-button type="text" @click="showSiftData" >{{$t("filter")}}</el-button>
        </div>
      </div>
      <el-table
        :data="table.data"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="direction" :label='$t("direction")' >
			<template #default="scope">
				{{$t(scope.row.direction)}}
			</template>
		</el-table-column>
        <el-table-column prop="order_no" :label='$t("order_no")' />
        <el-table-column prop="description" :label='$t("description")' />
        <el-table-column prop="amount" :label='$t("amount")' />
        <el-table-column prop="created_at" :label='$t("time")' />
      </el-table>
      <!-- 分页 -->
      <el-pagination
        class="pagination-right"
        @current-change="requestData"
        v-model:currentPage="table.pagination.currentPage"
        :page-size="table.pagination.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="table.pagination.total"
      >
      </el-pagination>
    </el-card>
    <sift
      v-model:dialogVisible="dialogSiftVisible"
      @reloadList="requestData"
      :defaultParams="table.queryParams"
    ></sift>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/Page.vue'
import { watch, ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { tableDefaultData, tableDataFormat } from '@/utils/table'
import { getPurchaseLogs } from '@/api/list'
import sift from './components/sift'
import { useRouter } from 'vue-router'
export default {
  name: 'sales',
  components: {
    Pagination,
	sift
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    let dialogSiftVisible = ref(false)

    //加载列表数据
    let table = tableDefaultData()
	if (parseInt(router.currentRoute.value.params.name)) {
	  table.queryParams.name = parseInt(router.currentRoute.value.params.name)
	}
    const requestData = (params) => {
      if (params) {
        Object.assign(table.queryParams, params)
        table.queryParams = {...table.queryParams,...params}
        table.queryParams = params
      }
      table.loading = true
      getPurchaseLogs(table.getQueryParams()).then((response) => {
        table.loading = false

        tableDataFormat(response, table)
      })
    }
    requestData()
    const showSiftData = () => {
      dialogSiftVisible.value = true
    }
    const reflashData = () => {

      Object.keys(table.queryParams).forEach(key => (table.queryParams[key] = ''));
      requestData()
    }
    return {
      dialogSiftVisible,
      table,
      requestData,
      showSiftData,
      reflashData
    }
  },
}
</script>

<style lang="scss" scoped>
.situationboard {
  display: flex;
  position: relative;
  height: 140px;
  min-width: 720px;
  width: calc(100% - 208px);
  .situation {
    text-align: left;
    line-height: 28px;
    min-width: 130px;
    padding: 24px 16px;
    .name {
      font-size: 14px;
    }
    .prev,
    .prevnum {
      color: #969799;
      font-size: 10px;
    }
  }
}
</style>