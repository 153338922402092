<!--
 * @Descripttion: 这是描述
 * @Date: 2022-04-26 11:16:28
 * @LastEditTime: 2022-09-20 10:49:39
-->
<!--
 * @Descripttion: 订单详情
 * @Date: 2022-03-22 09:31:16
 * @LastEditTime: 2022-04-26 11:16:00
-->
<template>
  <el-dialog :modelValue="dialogVisible" width="30%" :title='$t("filter")'>
    <el-form ref="formRef" :model="form" label-width="120px">
      <el-form-item :label='$t("withdraw_type")' prop="account_type">
		<el-select v-model="form.account_type" clearable >
			<el-option v-for="(item,index) in withdraw_type" :label="item" :value="index"></el-option>
		</el-select>
      </el-form-item>
	  <el-form-item :label='$t("withdraw_status")' prop="status">
	  		<el-select v-model="form.status" clearable >
	  			<el-option v-for="(item,index) in withdraw_status" :label="item" :value="index"></el-option>
	  		</el-select>
	  </el-form-item>
      <el-form-item :label='$t("daterange")' prop="daterange">
        <el-date-picker
          v-model="form.daterange"
          type="daterange"
          unlink-panels
          :range-separator='$t("to")'
          value-format="YYYY-MM-DD"
          :start-placeholder='$t("start_time")'
          :end-placeholder='$t("end_time")'
          @change="daterangeChange"
        />
      </el-form-item>
      <el-form-item>
        <el-button @click="handleRest">{{$t("reset")}}</el-button>
        <el-button type="primary" @click="handleSift">{{$t("filter")}}</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { watch, reactive, ref, onMounted, onBeforeUpdate, computed } from 'vue'

import { useStore } from 'vuex'

export default {
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
    },
    defaultParams: {
      type: Object,
    },
	withdraw_type:{
	  type:Object
	},
	withdraw_status:{
	  type:Object
	}
  },
  emits: ['reloadList', 'update:dialogVisible'],
  setup(props, { emit }) {
    const store = useStore()
    let formLabelWidth = '100px'
    const formRef = ref(null)
    let form = reactive({
      account_type: '',
      status: '',
      start_time: '',
      end_time: '',
    })
    const daterangeChange = (date, mode, view) => {
      console.log('form.daterange', form.daterange)
      console.log(date, mode, view)
    }
    onMounted((params) => {
      // console.log('dialogCreate mounted')
    })

    watch(
      () => props.dialogVisible,
      (v) => {
        //更换父组件dialogVisible的值
        emit('update:dialogVisible', v)
        if (v) {
          console.log('props.defaultParams', props.defaultParams)
          for (let i in props.defaultParams) {
            if (form.hasOwnProperty(i)) {
              form[i] = props.defaultParams[i]
            }
          }
        }
      }
    )
    const handleSift = () => {
      if (form.daterange) {
        form.start_time = form.daterange[0] + ' 00:00:00'
        form.end_time = form.daterange[1] + ' 23:59:59'
      } else {
        form.start_time = ''
        form.end_time = ''
      }
      const siftForm = Object.assign({}, form)
      emit('reloadList', siftForm)
      emit('update:dialogVisible', false)
    }
    const handleRest = () => {
      formRef.value.resetFields()
      const siftForm = Object.assign({}, form)
      emit('reloadList', siftForm)
    }

    //权限

    return {
      formLabelWidth,
      form,
      formRef,
      handleRest,
      handleSift,
      daterangeChange,
    }
  },
}
</script>

<style lang="scss">
</style>