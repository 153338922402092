/*
 * @Descripttion: 这是描述
 * @Date: 2022-03-19 09:23:01
 * @LastEditTime: 2022-03-22 16:55:57
 */
import regionalUsersPage from "@/views/regionalUsers"

export default [
    {
      name: 'regionalUsers',
      path: '/regionalUsers',
      meta: {
        id:'3',
        icon:'Promotion',
        title: 'regionalUsers',
        name:"area_user",
        cache: true,
		permission:"regional-user"
      },
      component: regionalUsersPage
    },
  ]
  