<!--
 * @Descripttion: 销售报表
 * @Date: 2022-03-19 15:28:23
 * @LastEditTime: 2022-05-31 09:14:50
-->
<template>
  <div>
    <el-card>
      <div class="u-flex u-100 u-m-b-20">
        <div>
          <span class="page-title">{{$t("referral_user")}}</span>
        </div>
        <div class="u-flex u-m-l-auto u-row-center">
		  <el-button type="primary" @click="addUser" v-if="is_bonus">{{$t("add_user")}}</el-button>
          <el-button type="text" @click="reflashData" >{{$t("refresh")}}</el-button>
          <el-button type="text" @click="showSiftData">{{$t("filter")}}</el-button>
        </div>
      </div>
      <el-table
        :data="table.data"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="username" :label='$t("user")' width="180" />
        <el-table-column v-if="!is_bonus" prop="grade" :label='$t("grade")' width="100" />
		<el-table-column v-if="is_bonus" prop="bonus_grade" :label='$t("bonus_grade")' width="100" />
        <el-table-column prop="invitation_level" :label='$t("invitation_level")' />
        <el-table-column prop="order_count" :label='$t("order_count")' />
        <el-table-column prop="order_amount" :label='$t("order_amount")' />
        <el-table-column prop="created_at" :label='$t("time")' />
      </el-table>
      <!-- 分页 -->
      <el-pagination
        class="pagination-right"
        @current-change="requestData"
        v-model:currentPage="table.pagination.currentPage"
        :page-size="table.pagination.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="table.pagination.total"
      >
      </el-pagination>
    </el-card>
    <sift
      v-model:dialogVisible="dialogSiftVisible"
      @reloadList="requestData"
      :defaultParams="table.queryParams"
	  :user_grade = "user_grade"
	  :relationship = "relationship"
    ></sift>
	<add-user
	  v-if="is_bonus"
	  v-model:dialogVisible="showUserDetail"
	  :user_grade = "new_user_grade"
	  :bonus_amount="bonus_amount"
	  @reloadList="requestData"
	></add-user>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/Page.vue'
import { watch, ref, reactive, computed,onBeforeCreate } from 'vue'
import { useStore } from 'vuex'
import { tableDefaultData, tableDataFormat } from '@/utils/table'
import { getRefferalUsersList } from '@/api/list'
import addUser from './components/addUser'
import sift from './components/sift'

import { useRouter } from 'vue-router'
export default {
  name: 'sales',
  components: {
    Pagination,
	sift,
	addUser
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    let dialogSiftVisible = ref(false)
    //加载列表数据
    let table = tableDefaultData()
    table.queryParams = {}
	let user_grade = ref({})
	let new_user_grade = ref({})
	let relationship = ref({})
	let showUserDetail = ref(false)
	let is_bonus = ref(false)
	let bonus_amount = ref({})
	is_bonus = computed(()=>store.getters.distribution_type === 'bonus');
    if (router.currentRoute.value.params.grade) {
      table.queryParams.grade = router.currentRoute.value.params.grade
    }
	if (router.currentRoute.value.params.invitation_level) {
	  table.queryParams.invitation_level = router.currentRoute.value.params.invitation_level
	}
	if (parseInt(router.currentRoute.value.params.username)) {
	  table.queryParams.username = router.currentRoute.value.params.username
	}
	if (router.currentRoute.value.params.start_time) {
	  table.queryParams.start_time = router.currentRoute.value.params.start_time
	}
	if (parseInt(router.currentRoute.value.params.end_time)) {
	  table.queryParams.end_time = parseInt(router.currentRoute.value.params.end_time)
	}
    const requestData = (params) => {
      if (params) {
        Object.assign(table.queryParams, params)
      }
      table.loading = true
      console.log('table.getQueryParams()',table.getQueryParams());
      getRefferalUsersList(table.getQueryParams()).then((response) => {
        table.loading = false

        tableDataFormat(response, table)
		if(!is_bonus){
			user_grade.value = response.data.user_grade
		}
		else{
			user_grade.value = response.data.bonus_grade
			user_grade.value.forEach((item,index)=>{
				if(index > 0){
					new_user_grade.value[index] = item;
				}
			})
			
			bonus_amount.value = response.data.bonus_amount
		}
		relationship.value = response.data.relationship
      })
    }
    requestData()
    const showSiftData = () => {
      dialogSiftVisible.value = true
    }
    const reflashData = () => {

      Object.keys(table.queryParams).forEach(key => (table.queryParams[key] = ''));
      console.log(table.queryParams);
      requestData()
    }
	const addUser = () => {
		showUserDetail.value = true
	}
    return {
      dialogSiftVisible,
      table,
      requestData,
      showSiftData,
      reflashData,
	  user_grade,
	  relationship,
	  addUser,
	  showUserDetail,
	  is_bonus,
	  bonus_amount,
	  new_user_grade
    }
  },
}
</script>

<style lang="scss" scoped>
.situationboard {
  display: flex;
  position: relative;
  height: 140px;
  min-width: 720px;
  width: calc(100% - 208px);
  .situation {
    text-align: left;
    line-height: 28px;
    min-width: 130px;
    padding: 24px 16px;
    .name {
      font-size: 14px;
    }
    .prev,
    .prevnum {
      color: #969799;
      font-size: 10px;
    }
  }
}
</style>