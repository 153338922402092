/*
 * @Descripttion: 这是描述
 * @Date: 2022-03-19 09:23:01
 * @LastEditTime: 2022-03-22 16:55:57
 */
import AccountPage from "@/views/account"
import InfoPage from "@/views/account/info"
import WithdrawallogPage from "@/views/account/withdrawallog"
import BindBank from "@/views/account/bindbank"

export default [
    {
      name: 'account',
      path: '/account',
	  component: AccountPage,
	  redirect: '/account',
      meta: {
        id:'4',
        icon:'Avatar',
        title: 'account',
        name:"account",
        cache: true,
		permission:"account"
      },
	  children: [{
	    name: 'info',
	    path: '/account/info',
	    component: InfoPage,
	    meta: {
	      id: '41',
	      title: 'info',
	      name: "base_info",
	      icon: 'UserFilled',
	      cache: true
	    },
	  },
	  {
	    name: 'bindBank',
	    path: '/account/bindbank',
	    component: BindBank,
	    meta: {
	      id: '42',
	      title: 'withdrawallog',
	      name: "withdraw_account",
	      icon: 'WalletFilled',
	      cache: true
	    },
	  },
	  {
	    name: 'withdrawallog',
	    path: '/account/withdrawallog',
	    component: WithdrawallogPage,
	    meta: {
	      id: '43',
	      title: 'withdrawallog',
	      name: "withdraw_log",
	      icon: 'List',
	      cache: true
	    },
	  }
	  ]
    },
  ]
  