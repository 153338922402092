<!--
 * @Descripttion: 这是描述
 * @Date: 2022-03-19 10:29:56
 * @LastEditTime: 2022-03-21 15:16:59
-->
<template>
  <el-sub-menu :index="item.path" v-if="item.children">
    <template #title>
        
      <el-icon v-if="item.meta.icon"><component  :is="icon(item.meta.icon)" /></el-icon>
      <span>{{  $t(item.meta.name) }}</span>
    </template>
    <template v-for="children in item.children">
      <nav-item v-if="children.children" :item="children" :key="children"></nav-item>
      <el-menu-item :index="children.path" :key="children.path" style="padding-left: 15px" v-else>
        <el-icon v-if="children.meta.icon"><component  :is="icon(children.meta.icon)" /></el-icon>
        {{ $t(children.meta.name) }}
      </el-menu-item>
    </template>
  </el-sub-menu>
  <el-menu-item :index="item.path" v-else>
    <el-icon v-if="item.meta.icon"><component  :is="icon(item.meta.icon)" /></el-icon>
    <el-icon v-else><component  :is="''" /></el-icon>
    <span>{{ $t(item.meta.name) }}</span>
  </el-menu-item>
</template>
<script>
import { computed } from 'vue'
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  name: 'NavItem',
  setup() {
    return {
      icon: computed(() => (name) => {
        return name
      })
    }
  }
}
</script>