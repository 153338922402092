<template>
	<div>
		<el-card :loading="loading">
			<div class="u-flex u-100 u-m-b-20">
				<div>
					<span class="page-title">{{ $t("base_info") }}</span>
				</div>
			</div>
			<el-form v-model="form">
				<el-form-item v-if="form.email" prop="email" :label='$t("email")' :label-width="formLabelWidth">
					<span>{{form.email}}</span>
				</el-form-item>
				<el-form-item v-if="form.mobile" prop="mobile" :label='$t("mobile")' :label-width="formLabelWidth">
					<span>{{form.mobile}}</span>
				</el-form-item>
				<el-form-item v-if="form.bonus_grade" prop="bonus_grade" :label='$t("bonus_grade")' :label-width="formLabelWidth">
					<span>{{form.bonus_grade}}</span>
				</el-form-item>
				<el-form-item v-if="form.pay_balance || form.pay_balance === 0" prop="pay_balance" :label='$t("pay_balance")' :label-width="formLabelWidth">
					<span>{{form.pay_balance}}</span>
				</el-form-item>
				<el-form-item prop="password" :label='$t("password")' :label-width="formLabelWidth">
					<span v-if="form.password">{{form.password}}</span>
					<span v-else>{{ $t("not_set") }}</span>
					<el-button :icon="EditPen" @click="openpwd"></el-button>
				</el-form-item>
				<el-form-item  prop="paypassword" :label='$t("paypassword")' :label-width="formLabelWidth">
					<span v-if="form.paypassword">{{form.paypassword}}</span>
					<span v-else>{{ $t("not_set") }}</span>
					<el-button :icon="EditPen" @click="openpaypwd"></el-button>
				</el-form-item>
				<el-form-item prop="balance" :label='$t("balance")' :label-width="formLabelWidth">
					<span>{{form.balance}}</span>
					<el-button @click="openWithdraw">{{ $t("withdraw") }}</el-button>
				</el-form-item>
				<el-form-item prop="invitation_url" :label='$t("invitation_url")' :label-width="formLabelWidth">
					<span>{{form.invitation_url}}</span>
					<el-button :icon="DocumentCopy" @click="handleCopy(form.invitation_url)"></el-button>
				</el-form-item>
			</el-form>
		</el-card>
		<pwd
		  v-model:dialogVisible="dialogSiftVisible"
		  @reloadList="requestData"
		  :type = 'pwd_type'
		  :userinfo = 'form'
		></pwd>
		<withdraw
		  v-model:dialogVisible="dialogWithdrawVisible"
		  @reloadList="requestData"
		  :withdraw_type = 'withdraw_type'
		  :userinfo = 'form'
		></withdraw>
	</div>	
</template>

<script>
	import {
		reactive,
		ref
	} from '@vue/reactivity';
	import useClipboard from 'vue-clipboard3'
	import { EditPen,DocumentCopy } from '@element-plus/icons-vue'
	import notice from '@/utils/notice'
	import pwd from './components/pwd'
	import withdraw from './components/withdraw'
	import {
		useStore
	} from 'vuex'
import { getUser } from '@/api/users';
import { useRouter } from "vue-router";
import i18n from '@/lang'
	export default {
		name: 'config',
		components: {
			pwd,
			withdraw
		},
		setup() {
			const store = useStore();
			const router = useRouter();
			const { t } = i18n.global
			let form = ref({
			  email: '',
			  mobile: '',
			  paypassword: '',
			  password: '',
			  invitation_url: '',
			})
			let formLabelWidth = '150px';
			let dialogSiftVisible = ref(false)
			let dialogWithdrawVisible = ref(false)
			let pwd_type = ref("password") //paypassword
			let loading = ref(false)
			let withdraw_type = ref({})
			
			//复制
			const {	toClipboard } = useClipboard();
			const handleCopy = async (text) => {
			  try {
			    await toClipboard(text)
			    notice.success(t("copy_success") + '...')
			  } catch (err) {
			    console.log('handleCopy err',err)
			    //notice.failed('复制失败');
			  }
			}
			const openpwd = function(){
				router.push({
				    path:"/change-password"
				})
			}
			const openpaypwd = function(){
				pwd_type.value = "paypassword"
				dialogSiftVisible.value = true
			}
			
			const requestData = (params) => {
			  loading.value = true
			  getUser().then((response) => {
				  console.log(response)
				  form.value = response.data
				  withdraw_type.value = response.data.withdraw_type
				  loading.value = false
			  }).catch(()=>{
				  loading.value = false
			  })
			}
			const openWithdraw = ()=>{
				dialogWithdrawVisible.value = true
			}
			requestData()
			return {
				form,
				formLabelWidth,
				EditPen,
				DocumentCopy,
				handleCopy,
				dialogSiftVisible,
				openpwd,
				openpaypwd,
				pwd_type,
				requestData,
				loading,
				withdraw_type,
				dialogWithdrawVisible,
				openWithdraw
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content-box {
		align-items: flex-start;
	}

	.img-box {
		position: relative;
	}

	.del-icon {
		color: red;
		background: #fff;
		font-size: 26px;
		border-radius: 13px;
		position: absolute;
		top: -5px;
		right: -5px;
		cursor: pointer;
	}

	.el-link {
		font-size: 16px;
		vertical-align: top;
	}

	.tips-span {
		padding: 0 0 20px 45px;
		color: #999999
	}

	.editor-btn {
		color: #8c7065;
		display: flex;
		align-items: center;
		padding-left: 90%;

		span {
			margin-left: 5px;
			cursor: pointer;
		}
	}
</style>