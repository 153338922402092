/*
 * @Descripttion: 这是描述
 * @Date: 2022-03-19 09:23:01
 * @LastEditTime: 2022-03-22 16:55:57
 */
import agentPage from "@/views/agent"

export default [
    {
      name: 'agent',
      path: '/agent',
      meta: {
        id:'1',
        icon:'Ticket',
        title: 'agent',
        name:"agent_apply",
        cache: true,
		permission:"agent"
      },
      component: agentPage
    },
  ]
  