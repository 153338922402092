<!--
 * @Descripttion: 这是描述
 * @Date: 2022-03-19 14:51:53
 * @LastEditTime: 2022-03-21 09:40:44
-->
<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
  export default {}
</script>

<style>
</style>