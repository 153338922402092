export default {
    "base_info":"Basic information",
	"email" : "Email",
	"mobile" :"Mobile",
	"short_email" : "Email",
	"short_mobile" :"Mobile",
	"password" : "Password",
	"not_set" : "Not set",
	"paypassword" : "Payment password",
	"balance" : "Balance",
	"withdraw" : "Withdraw",
	"invitation_url" : "Promotional links",
	"copy_success" : "Copy succeed",
	"withdraw_log" : "Withdrawal history",
	"refresh" : "Refresh",
	"filter" : "Filter",
	"withdraw_amount" : "Withdraw amount",
	"withdraw_type" : "Withdrawal method",
	"withdraw_account":"Withdraw account",
	"withdraw_status" : "Withdrawal status",
	"status":"Status",
	"memo" : "Remark",
	"time" : "Time",
	"daterange" : "Time",
	"to":"To",
	"start_time" : "Start time",
	"end_time" : "End time",
	"reset" : "Reset",
	"please_input_vercode" : "Please enter the verification code",
	"send_input_vercode" : "Send a verification code",
	"new_pwd" : "New password",
	"re_pwd" : "Confirm password",
	"pwd_tip" : "6-35 characters, including upper and lower letters, lowercase letters and numbers",
	"pre_step" : "Prev",
	"next_step" : "Next",
	"submit" : "Submit",
	"please_input_piccode" : "Please enter the image verification code",
	"send":"Send",
	"can_withdraw_amount" : "Amount of cash withdrawable",
	"cancel" : "Cancel",
	"change_pwd" : "Change the password",
	"change_pwd_tip" : "After the modification is successful, the current login will be automatically logged out！",
	"old_pwd" : "Old password",
	"login" : "Login",
	"please_input_account" : "Please enter your login account",
	"please_input_pwd" : "Please enter a password",
	"need_account" : "User name is required",
	"need_pwd" : "Password is required",
	"bonus_log" : "Split records",
	"consume_username":"Consumer users",
	"consume_amount":"Amount spent",
	"consume_type":"Type of consumption",
	"bonus_type":"Divided into types",
	"bonus_rate":"Split proportions",
	"bonus_amount":"Split amount",
	"user_info" : "User Information",
	"referral_user":"Promote users",
	"user":"User",
	"grade" :"Identity level",
	"invitation_level":"Hierarchical relationships",
	"order_count":"Number of purchases",
	"order_amount":"Total consumption",
	"area_user" : "Users within the zone",
	"region":"Region of belonging",
	"user_totle_line" : "Total number of promoted users",
	"grade_0" : "Promote the number of regular users",
	"grade_1" : "Number of promoted distributions",
	"grade_2" : "Promote the number of district-level agents",
	"grade_3" : "Promote the number of city-level agents",
	"grade_4" : "Promote the number of provincial-level agents",
	"total_bonus_count_line" : "Split into total transactions",
	"count_order_type_10" : "The number of product purchases",
	"count_order_type_11" : "The number of product renewals",
	"count_order_type_12" : "Number of value-added services",
	"count_order_type_20" : "Number of affiliate affiliations",
	"count_order_type_21" : "Number of agent renewals",
	"count_bonus_type_10" : "Direct push fraction",
	"count_bonus_type_11" : "Split into numbers",
	"count_bonus_type_20" : "The province is divided into numbers",
	"count_bonus_type_21" : "The city is divided into numbers",
	"count_bonus_type_22" : "District algebra is divided into numbers",
	"total_bonus_amount_line" : "Share the total amount",
	"amount_order_type_10" : "Product purchase amount",
	"amount_order_type_11" : "Product renewal amount",
	"amount_order_type_12" : "Value-added service amount",
	"amount_order_type_20" : "Agency Franchise Amount",
	"amount_order_type_21" : "Agent renewal amount",
	"amount_bonus_type_10" : "Direct Push Share",
	"amount_bonus_type_11" : "Split the amount from time to time",
	"amount_bonus_type_20" : "Provincial Share",
	"amount_bonus_type_21" : "City Share",
	"amount_bonus_type_22" : "District substitution shares",
	"num_of_user":"Number of promoted users",
	"by_order_type":"By type of consumption",
	"by_bonus_type":"According to the type of division",
	"set_pwd":"Set the login password",
	"set_paypwd":"Set a payment password",
	"near_7_days" : "Last 7 days",
	"near_15_days" : "Last 15 days",
	"near_30_days" : "Last 30 days",
	"near_1_year" : "Last 1 year",
	"bank_card":"Bank cards",
	"withdraw_info":"Withdraw account information",
	"name":"Name",
	"card_number":"Card number",
	"bank_name" : "Account bank",
	"save":"Save",
	"pleace_input_name":"Please enter your name",
	"pleace_input_cardno":"Please enter the card number",
	"pleace_input_bankname":"Please enter the bank where you have an account",
	"agent_apply" : "Apply for a proxy",
	"apply_has_submit_waiting":"Your proxy application has been submitted, please wait for the platform to review...",
	"apply_has_pass_pay":"Your agency application has been approved, please pay the agency fee in time!",
	"apply_has_paid_waiting":"Your agency application has been approved and the agency fee has been paid, please wait for the platform to confirm...",
	"apply_has_paid":"You have officially signed up to become an agent of this platform, and you will enjoy the agent commission of this platform!",
	"agent_area":"Proxy area",
	"company_name":"Company name",
	"company_size":"Company size",
	"work_address":"Office address",
	"work_address":"Please enter your full address",
	"company_size":"Company size",
	"contact_person" : "Contact",
	"please_input_contact_person":"Please enter the contact name",
	"contact_type":"Contact type",
	"service_phone":"Service calls",
	"pleace_input_service_phone":"Please enter a phone number",
	"apply_info":"Application instructions",
	"submit_apply":"Submit an application",
	"agent_fee":"Agency fees",
	"apply_time":"Application time",
	"paid_time":"Payment time",
	"service_address":"Service address",
	"next_is_area_agent_info":"Below is your provincial agent information",
	"pay_now":"Pay",
	"cancel_agent":"Revocation of the application",
	"apply_pass_time":"Signing time",
	"no_account":"Don't have an account yet?",
	"register_in_app":"Register with the application system now",
	"wechat" : "Wechat",
	"account":"Account",
	"pleace_input_account":"Please enter the account number",
	"alipay":"Alipay",
	"bonus_grade":"Proxy level",
	"pay_balance":"Payment balance",
	"agent_purchase_logs":"Payment records",
	"amount":"Amount",
	"direction":"Direction",
	"description":"Description",
	"in":"In",
	"out":"Out",
	"order_no":"Order number",
	"add_user":"Add User",
	"please_input_mobile" : "Please input your phone number",
	"please_input_emial":"Please input the email address ",
	"please_input_pwd" : "Please input the password",
	"please_confirm_pwd":"Please confirm the password",
	"passwords_do_not_match":"The two passwords do not match",
	"home":"Home",
	"login_out":"Login out",
	"pleace_input_account":"Please input an account",
	"alipay":"Alipay",
	"phone":"Phone",
	"please_input_agent_area":"Please select the proxy type and region",
	"please_input_company_name":"Please fill in the company name",
	"please_select_company_size":'Please select the company size',
	"please_input_contact_name":'Please fill in the contact name',
	"please_select_contact_information":"Please select contact information",
	"please_input_the_application_description":"Please enter the application description",
	"action_success":"success",
	"order_failed_please_initiate_payment_agent":"Order payment failed, please initiate payment again",
	"payment_order_does_not_exist":"Payment order does not exist",
	"please_select_the_verification_method":"Please select the verification method",
	"please_enter_the_verification_code":"Please enter the verification code",
	"please_enter_a_new_password":"Please enter a new password",
	"please_confirm_the_new_password":"Please confirm the new password",
	"the_two_passwords_are_different":"The two passwords are different",
	"buy_desc":"Cost: $1 (purchase $3 sets of products at a $2 discount, original price: $ $4/set)）"
}