/*
 * @Descripttion: 首页统计
 * @Date: 2022-05-30 16:33:14
 * @LastEditTime: 2022-05-30 17:25:28
 */

import http from '@/utils/http'


//统计
export const getMainStatTop = (params) => {
    return http.get('main/mainStatTop', { params })
};

//统计
export const getMainStatDown = (params) => {
    return http.get('main/mainStatDown', { params })
};


